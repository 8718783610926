import * as yup from 'yup';
import { parse } from 'date-fns';
import moment from 'moment';

const CO_MESSAGE = 'Campo Obrigatório';
const DT_MESSAGE = 'Data inválida';

export function formatExpiryToValidateDate(expiry) {
  if (expiry && expiry.length === 5) {
    const actualYear = new Date().getFullYear().toString();
    const month = expiry[0].concat(expiry[1]);
    const year = `${actualYear[0]}${actualYear[1]}${expiry[3].concat(expiry[4])}`;
    const dummyDay = '01';
    const possibleDate = `${year}-${month}-${dummyDay}`;

    return parse(possibleDate, 'yyyy-MM-dd', new Date());
  }
  return '';
}

export const schema = yup.object().shape({
  number: yup.string().matches(/^[0-9]+$/, 'Número inválido').min(16, 'Número inválido').required(CO_MESSAGE),
  name: yup.string().min(2, CO_MESSAGE).required(CO_MESSAGE).typeError(CO_MESSAGE),
  expiry: yup.date()
    .min(moment().startOf('month').format('YYYY-MM-DD'), DT_MESSAGE)
    .max(moment().add(10, 'year').format('YYYY-MM-DD'), DT_MESSAGE)
    .required(CO_MESSAGE)
    .typeError(DT_MESSAGE),
  cvc: yup.string().min(3, CO_MESSAGE).max(3, CO_MESSAGE).required(CO_MESSAGE)
    .typeError(CO_MESSAGE),
});

export function resetErrors() {
  return oldState => ({
    ...oldState,
    number: {
      error: false,
      message: '',
    },
    name: {
      error: false,
      message: '',
    },
    expiry: {
      error: false,
      message: '',
    },
    cvc: {
      error: false,
      message: '',
    },
  });
}

function limit(val, max) {
  let aVal = val;
  if (val.length === 1 && val[0] > max[0]) {
    aVal = `0${val}`;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      aVal = '01';
    } else if (val > max) {
      aVal = max;
    }
  }

  return aVal;
}

export function cardExpiry(val) {
  const month = limit(val.substring(0, 2), '12');
  const year = val.substring(2, 4);

  return month + (year.length ? `/${year}` : '');
}
