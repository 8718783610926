import { formaPagamentoEnum } from '../../utils/utils.constants';

export function getOperacao(formaPagamento) {
  if (formaPagamento === formaPagamentoEnum.CARTAO_CREDITO) {
    return 'CARTAO_CREDITO';
  }
  if (formaPagamento === formaPagamentoEnum.PIX) {
    return 'PIX';
  }
  return 'BOLETO';
}
