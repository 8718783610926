import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  stepsContainer: {
    marginBottom: '4%',
  },
  stepsImg: {
    height: 'auto',
    width: 'auto',
    maxWidth: '85%',
    margin: '0% 3.8%',
    padding: '0% 8.9%',
  },
  topicsContainerItem: {
    marginBottom: '0px',
  },
});
