import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import useStyles from './topico.styles';
import GrowUI from '../Transitions/GrowUI';

function Topico({
  src,
  alt,
  title,
  text,
  imgMaxWidth,
  imgHeight,
  timeoutEffect,
}) {
  const styles = useStyles({ imgMaxWidth, imgHeight });

  const loading = useSelector(states => states.homeStore.actionsPageView.loadingHome);

  return (
    <GrowUI loading={loading} timeoutEffect={timeoutEffect}>
      <Grid container spacing={2} className={styles.topicoContainerTopic}>
        <Grid container item justify="center" alignItems="flex-start" lg={3} md={3} sm={3} xs={3}>
          <img
            className={styles.topicoImg}
            src={src}
            alt={alt}
          />
        </Grid>
        <Grid container lg={9} item md={9} sm={9} xs={9}>
          <Grid container item lg={12} md={12} sm={12} xs={12} alignItems="flex-end">
            <Typography component="p" className={styles.topicoTitle}>
              {title}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography component="p" className={styles.topicoSubTitle}>
              {text}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </GrowUI>
  );
}

export default Topico;
