import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import {
  API_GET_DADOS_CREDOR, API_GET_DADOS_RECIBO,
  API_GET_DEVEDOR,
  API_GET_LOGO_CLIENTE,
  API_GET_OUTRAS_DIVIDAS,
  API_GET_PARAMETROS_GERAIS,
  API_GET_TITULOS,
  API_GET_BOLETO_ACORDO, API_POST_ENVIAR_EMAIL_FALE_CONOSCO, API_GET_PIX_ACORDO, API_POST_COMPROVANTE_EMAIL,
} from './home.constants';
import {
  clearStatesFaleConoscoAction,
  setActionsPageViewAction,
  setCapaHomeAction,
  setDadosCredorAction, setDadosEnviarComprovanteAction, setDadosReciboAction,
  setDevedorAction, setFaleConoscoAction,
  setGlobalsActions,
  setOutrasDividasAction,
  setParametrosGeraisActions,
  setTitulosAction,
} from './home.store';
import {
  createPDFFileAndOpen, getExceptionMessage, getNumeroControle, getRandomNumber, saveImageSessionStorage,
} from '../../utils/utils';

import {
  enviarComprovanteEmail,
  enviarEmail,
  getDadosCredor,
  getDevedor,
  getLogoCliente,
  getOutrasDividas,
  getParametrosGerais,
  getTitulosProcesso,
} from '../../services/Home/home.service';
import { getBoletoAcordo, getPixAcordo, getReciboCartaoCredito } from '../../services/Acordo/acordo.service';

export const getTitulosAction = () => ({
  type: API_GET_TITULOS,
});

export const getDevedorAction = () => ({
  type: API_GET_DEVEDOR,
});

export const getDadosCredorAction = () => ({
  type: API_GET_DADOS_CREDOR,
});

export const getParametrosGeraisAction = () => ({
  type: API_GET_PARAMETROS_GERAIS,
});

export const getOutrasDividasAction = () => ({
  type: API_GET_OUTRAS_DIVIDAS,
});

export const getLogoClienteAction = () => ({
  type: API_GET_LOGO_CLIENTE,
});

export const getDadosReciboAction = () => ({
  type: API_GET_DADOS_RECIBO,
});

export const getBoletoAcordoAction = idRecebimento => ({
  type: API_GET_BOLETO_ACORDO,
  idRecebimento,
});

export const getPixAcordoAction = idRecebimento => ({
  type: API_GET_PIX_ACORDO,
  idRecebimento,
});

export const enviarEmailFaleConoscoAction = () => ({
  type: API_POST_ENVIAR_EMAIL_FALE_CONOSCO,
});

export const enviarComprovanteEmailAction = () => ({
  type: API_POST_COMPROVANTE_EMAIL,
});

function* getDadosCredorHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const idCliente = yield select(states => states.homeStore.searchCriteria.idCliente);
    const { data } = yield call(getDadosCredor, idCliente);
    yield put(setDadosCredorAction(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getDevedorHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const idDevedor = yield select(states => states.homeStore.searchCriteria.idDevedor);
    const { data } = yield call(getDevedor, idDevedor);
    yield put(setDevedorAction(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getTitulosHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const numeroControleAcordoSeguro = getNumeroControle();
    const { data } = yield call(getTitulosProcesso, numeroControleAcordoSeguro);
    yield put(setTitulosAction(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getParametrosGeraisHandler() {
  yield put(setActionsPageViewAction('loading', true));
  yield put(setActionsPageViewAction('loadingHome', true));
  yield put(setCapaHomeAction(getRandomNumber()));
  try {
    const numeroControleAcordoSeguro = getNumeroControle();
    const { data } = yield call(getParametrosGerais, numeroControleAcordoSeguro);

    yield put(setParametrosGeraisActions(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
    yield put(setActionsPageViewAction('loadingHome', false));
  }
}

function* getOutrasDividasHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const idProcesso = yield select(store => store.homeStore.searchCriteria.idProcesso);
    const { data } = yield call(getOutrasDividas, idProcesso);
    yield put(setOutrasDividasAction(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getLogoClienteHandler() {
  yield put(setActionsPageViewAction('loadingLogoCliente', true));

  try {
    const logoCliente = yield select(store => store.homeStore.logoCliente);
    const idCliente = yield select(store => store.homeStore.searchCriteria.idCliente);

    const newImage = { ...logoCliente };
    const { data } = yield call(getLogoCliente, idCliente);
    newImage.imagePreviewUrl = `data:image;base64,${data}`;
    yield put(setGlobalsActions('logoCliente', newImage));
    yield saveImageSessionStorage(newImage);
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loadingLogoCliente', false));
  }
}

function* getDadosReciboHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const idProcesso = yield select(store => store.homeStore.searchCriteria.idProcesso);
    const { data } = yield call(getReciboCartaoCredito, idProcesso);

    yield put(setDadosReciboAction(data));
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getBoletoAcordoHandler(actions) {
  const { idRecebimento } = actions;
  yield put(setActionsPageViewAction('loading', true));
  try {
    const { data } = yield call(getBoletoAcordo, idRecebimento);
    yield createPDFFileAndOpen(data);
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function* getPixAcordoHandler(actions) {
  const { idRecebimento } = actions;
  yield put(setActionsPageViewAction('loading', true));
  try {
    const { data } = yield call(getPixAcordo, idRecebimento);
    yield createPDFFileAndOpen(data);
  } catch (exception) {
    // Nenhum tratamento definido
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

function buildEmailFaleConoscoPayload(faleConosco, idCliente) {
  return {
    motivo: faleConosco.motivo,
    emailFaleConosco: faleConosco.emailFaleConosco,
    emailDestinatarioDevedor: faleConosco.email,
    mensagem: faleConosco.mensagem,
    idCliente,
    nomeDevedor: faleConosco.nome,
  };
}

function* configureMessageExceptionEmail(error, message, iconMessage) {
  yield put(setFaleConoscoAction('errorEnvioEmail', error));
  yield put(setFaleConoscoAction('messageAposEnvio', message));
  yield put(setFaleConoscoAction('iconMessageAposEnvio', iconMessage));
}

function* enviarEmailFaleConoscoHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const faleConosco = yield select(store => store.homeStore.faleConosco);
    const idCliente = yield select(store => store.homeStore.searchCriteria.idCliente);

    const emailContent = yield buildEmailFaleConoscoPayload(faleConosco, idCliente);
    yield call(enviarEmail, emailContent);

    yield configureMessageExceptionEmail(false, 'Agradecemos o contato. Sua mensagem será respondida em breve', 'fas fa-check-circle');
  } catch (exception) {
    yield configureMessageExceptionEmail(true, getExceptionMessage(exception), 'fas fa-exclamation-circle');
  } finally {
    yield put(setActionsPageViewAction('loading', false));
    yield put(clearStatesFaleConoscoAction());
  }
}

function buildEnviarComprovanteEmailPayload(emailDestinatarioDevedor, ddd, telefone, nomeDevedor, idCliente, idDevedor, idProcesso) {
  return {
    idCliente,
    idProcesso,
    idDevedor,
    nomeDevedor,
    emailDestinatarioDevedor,
    ddd,
    telefone,
  };
}

function buildDialog(contentText, infoContentText, success) {
  return {
    infoContentText,
    contentText,
    confirmButtonText: 'OK',
    isSucess: success,
    open: true,
  };
}

function* configureDialog(message, sucess) {
  const dialog = yield buildDialog(message, '', sucess);
  yield put(setDadosEnviarComprovanteAction('dialog', dialog));
}

function* enviarComprovanteEmailHandler() {
  yield put(setActionsPageViewAction('loading', true));
  try {
    const idDevedor = yield select(store => store.homeStore.searchCriteria.idDevedor);
    const { emailDestinatarioDevedor, ddd, telefone } = yield select(store => store.homeStore.dadosEnviarCompravante);
    const idCliente = yield select(store => store.homeStore.searchCriteria.idCliente);
    const idProcesso = yield select(store => store.homeStore.searchCriteria.idProcesso);

    // Busca o devedor.
    const { data } = yield call(getDevedor, idDevedor);

    const payload = yield buildEnviarComprovanteEmailPayload(emailDestinatarioDevedor, ddd, telefone, data.nome, idCliente, idDevedor, idProcesso);
    yield call(enviarComprovanteEmail, payload);

    // Abre o dialog de sucesso.
    yield configureDialog('E-mail enviado com sucesso!', true);
  } catch (exception) {
    // Abre o dialog de erro.
    yield configureDialog('Falha no envio do e-mail.', false);
  } finally {
    yield put(setActionsPageViewAction('loading', false));
  }
}

export default function* watchHome() {
  yield takeLatest(API_GET_TITULOS, getTitulosHandler);
  yield takeLatest(API_GET_DEVEDOR, getDevedorHandler);
  yield takeLatest(API_GET_PARAMETROS_GERAIS, getParametrosGeraisHandler);
  yield takeLatest(API_GET_DADOS_CREDOR, getDadosCredorHandler);
  yield takeLatest(API_GET_OUTRAS_DIVIDAS, getOutrasDividasHandler);
  yield takeLatest(API_GET_LOGO_CLIENTE, getLogoClienteHandler);
  yield takeLatest(API_GET_DADOS_RECIBO, getDadosReciboHandler);
  yield takeLatest(API_GET_BOLETO_ACORDO, getBoletoAcordoHandler);
  yield takeLatest(API_POST_ENVIAR_EMAIL_FALE_CONOSCO, enviarEmailFaleConoscoHandler);
  yield takeLatest(API_GET_PIX_ACORDO, getPixAcordoHandler);
  yield takeLatest(API_POST_COMPROVANTE_EMAIL, enviarComprovanteEmailHandler);
}
