import * as yup from 'yup';

const CO_MESSAGE = 'Campo Obrigatório';

export const faleConoscoSchema = yup.object().shape({
  nome: yup.string().required(CO_MESSAGE),
  email: yup.string().matches(/^[_A-Za-z0-9-\\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/, 'E-mail inválido')
    .required(CO_MESSAGE),
  mensagem: yup.string().required(CO_MESSAGE),
});

export const motivosEnum = {
  solicitacao: 'SOLICITACAO',
  sugestao: 'SUGESTAO',
  reclamacao: 'RECLAMACAO',
  elogio: 'ELOGIO',
};

export const errorFaleConoscoInitialState = {
  nome: {
    error: false,
    message: '',
  },
  email: {
    error: false,
    message: '',
  },
  mensagem: {
    error: false,
    message: '',
  },
};

export function resetErrors() {
  return oldState => ({
    ...oldState,
    nome: {
      error: false,
      message: '',
    },
    email: {
      error: false,
      message: '',
    },
    mensagem: {
      error: false,
      message: '',
    },
  });
}
