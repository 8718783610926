import makeStyles from '@material-ui/core/styles/makeStyles';
import { VERY_LIGHT_GRAY } from '../../../utils/colors/colors.contants';

export default makeStyles({
  container: {
    backgroundColor: VERY_LIGHT_GRAY,
  },
  children: {
    maxWidth: '1280px',
    width: '100%',
    vw: '100%',
    margin: 'auto',
  },
});
