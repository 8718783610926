import React, { memo, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { formatDate, formatMoney, generateUniqId } from '../../../utils/utils';
import useStyles from './CollapsiTableUI.styles';
import FadeUI from '../Transitions/FadeUI';

function Row({ row }) {
  const styles = useStyles();
  const loading = useSelector(states => states.homeStore.actionsPageView.loading);
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={styles.tableRowContainerTitle}>
        <FadeUI loading={loading} timeoutEffect={800}>
          <TableCell>{row.idProcesso}</TableCell>
        </FadeUI>
        <FadeUI loading={loading} timeoutEffect={1000}>
          <TableCell>{row.clienteNome}</TableCell>
        </FadeUI>
        <TableCell>
          <IconButton size="medium" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={styles.subTableContainer} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography className={styles.subTableTitle} gutterBottom component="div">
                RELAÇÃO DE TÍTULOS
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow className={styles.tableRowContainer}>
                    <TableCell align="center">Número</TableCell>
                    <TableCell align="center">Vencimento</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.titulos.map(titulo => (
                    <TableRow key={generateUniqId()} className={styles.tableRowContainer}>
                      <TableCell align="center">{titulo.numero}</TableCell>
                      <TableCell align="center">{formatDate(titulo.dataVencimento, false)}</TableCell>
                      <TableCell align="right">{formatMoney(titulo.valorSaldoPrincipal)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function CollapsibleTableUI({ rows, columns }) {
  const styles = useStyles();
  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table stickyHeader className={styles.tableheaderContainer}>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
              >
                <Typography component="p">
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <Row key={generateUniqId()} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(CollapsibleTableUI);
