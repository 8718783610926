import {
  call, put, select, takeLatest,
} from 'redux-saga/effects';
import { API_GET_OPCOES_ACORDO, API_REALIZAR_ACORDO } from './negociar.constants';
import {
  selectOpcaoPagamentoAction,
  setActionsNegociarPageViewAction,
  setDialogPageViewAction,
  setOpcoesPagamentoActions,
} from './negociar.store';
import {
  getBoletoAcordo, getPixAcordo,
  getPossiveisAcrodo,
  realizarAcordoDividaBoleto,
  realizarAcordoDividaCartaoCredito, realizarAcordoDividaPix,
} from '../../services/Acordo/acordo.service';
import {
  createPDFFileAndOpen,
  formatedDate,
  getExceptionInNumberCard,
  getExceptionMessage,
  isBadRequestException,
  isInternalErrorException,
  isNotFoundException,
  replaceTo, saveObjectSessionStorage,
} from '../../utils/utils';
import { formaPagamentoEnum, GENERIC_ERROR_MSG } from '../../utils/utils.constants';
import { getOperacao } from './negociar.saga.utils';


export const getOpcoesAcordoAction = (formaPagamento, idProcesso) => ({
  type: API_GET_OPCOES_ACORDO,
  formaPagamento,
  idProcesso,
});

export const realizarAcordoDividaAction = (formaPagamento, idProcesso, history, fncSetErroCardValidation) => ({
  type: API_REALIZAR_ACORDO,
  formaPagamento,
  idProcesso,
  history,
  fncSetErroCardValidation,
});

function* generateAndShowException(exception) {
  const exceptionMessage = getExceptionMessage(exception);
  const notFound = isNotFoundException(exception);
  const badRequest = isBadRequestException(exception);
  const internalException = isInternalErrorException(exception);

  if (notFound || badRequest || internalException) {
    const dialog = {
      infoContentText: 'Ops!',
      contentText: exceptionMessage || GENERIC_ERROR_MSG,
      confirmButtonText: 'OK',
      isSucess: false,
      open: true,
    };
    yield put(setDialogPageViewAction(dialog));
  }
}

function buildDialog(contentText, infoContentText, success) {
  return {
    infoContentText,
    contentText,
    confirmButtonText: 'OK',
    isSucess: success,
    open: true,
  };
}

function* pagamentoRealizaComSucessoCartaoCredito() {
  const dialog = buildDialog('Solicitação de pagamento realizada com sucesso! '
    + 'Aguardaremos a confirmação da operadora do cartão para aprovar o pagamento.', '', true);
  yield put(setDialogPageViewAction(dialog));
}


function* generateAcordCartaoCreditoPayload() {
  try {
    const dadosCartao = yield select(store => store.dadosCartaoCredito);
    const opcaoPagamentoSelected = yield select(store => store.opcaoPagamentoSelected);
    return {
      qtdeParcelas: opcaoPagamentoSelected.qtdeParcelas,
      valorParcela: opcaoPagamentoSelected.valorParcela,
      valorTotal: opcaoPagamentoSelected.valorTotal,
      cartaoCredito: {
        numero: dadosCartao.number,
        codSeguranca: dadosCartao.cvc,
        dataVencimento: formatedDate(dadosCartao.expiry),
        nome: dadosCartao.name,
      },
      cliente: {
        nome: dadosCartao.name,
      },
    };
  } catch (exception) {
    const dialog = buildDialog(GENERIC_ERROR_MSG, '', false);
    yield put(setDialogPageViewAction(dialog));
  }
  return null;
}

function* realizarAcordoDividaBoletoHandler(idProcesso, history) {
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const opcaoPagamentoSelected = yield select(store => store.opcaoPagamentoSelected);
    const response = yield call(realizarAcordoDividaBoleto, idProcesso, opcaoPagamentoSelected);
    if (response.data) {
      const { idRecebimento } = response.data;
      const { data } = yield call(getBoletoAcordo, idRecebimento);
      yield createPDFFileAndOpen(data);
      yield replaceTo(history, '/');
    }
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}

function* realizarAcordoDividaCartaoCreditoHandler(idProcesso, fncSetErroCardValidation) {
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const payload = yield generateAcordCartaoCreditoPayload();
    yield call(realizarAcordoDividaCartaoCredito, idProcesso, payload);
    yield pagamentoRealizaComSucessoCartaoCredito();
  } catch (exception) {
    const badRequest = isBadRequestException(exception);
    if (badRequest) {
      const error = getExceptionInNumberCard(exception);
      if (error) {
        yield fncSetErroCardValidation('number', true, error);
      }
    } else {
      yield generateAndShowException(exception);
    }
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}

function* realizarAcordoDividaPixHandler(idProcesso, history) {
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const opcaoPagamentoSelected = yield select(store => store.opcaoPagamentoSelected);
    const response = yield call(realizarAcordoDividaPix, idProcesso, opcaoPagamentoSelected);
    if (response.data) {
      const { idRecebimento } = response.data;
      const { data } = yield call(getPixAcordo, idRecebimento);
      yield createPDFFileAndOpen(data);
      yield replaceTo(history, '/');
    }
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}


function* realizarAcordoDividaHandler(actions) {
  const {
    formaPagamento, idProcesso, history, fncSetErroCardValidation,
  } = actions;
  if (formaPagamento === formaPagamentoEnum.PIX) {
    yield realizarAcordoDividaPixHandler(idProcesso, history);
  } else if (formaPagamento === formaPagamentoEnum.BOLETO) {
    yield realizarAcordoDividaBoletoHandler(idProcesso, history);
  } else {
    yield realizarAcordoDividaCartaoCreditoHandler(idProcesso, fncSetErroCardValidation);
  }
  yield saveObjectSessionStorage('reloadHome', true);
}

function* getOpcoesAcordoHandler(actions) {
  const { formaPagamento, idProcesso } = actions;
  yield put(setActionsNegociarPageViewAction('loading', true));
  try {
    const operacao = yield getOperacao(formaPagamento);
    const { data } = yield call(getPossiveisAcrodo, idProcesso, operacao);
    yield put(setOpcoesPagamentoActions(data));
    yield put(selectOpcaoPagamentoAction(data[0]));
  } catch (exception) {
    yield generateAndShowException(exception);
  } finally {
    yield put(setActionsNegociarPageViewAction('loading', false));
  }
}

export default function* watchNegociar() {
  yield takeLatest(API_GET_OPCOES_ACORDO, getOpcoesAcordoHandler);
  yield takeLatest(API_REALIZAR_ACORDO, realizarAcordoDividaHandler);
}
