import RadioGroup from '@material-ui/core/RadioGroup';
import styled from 'styled-components';

export const GroupDefinitions = styled(RadioGroup)`
  margin-left: 8px;
  flex-direction: row;
  width: 100%;

  span {
    margin-right: 4px;
    font-size: 16px;
  }

  label {
    width: fit-content;

    &:last-child {
      margin-top: 13px;
    }
  }
`;
