import React from 'react';
import Paper from '@material-ui/core/Paper';
import useStyles from './paper.styles';

function PaperUI({ children }) {
  const styles = useStyles();
  return (
    <Paper className={styles.container}>
      {children}
    </Paper>
  );
}

export default PaperUI;
