import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router';
import { startAuthenticationAction } from '../store/autenticacao/autenticacao.saga';
import UILoading from '../components/UI/Loading/UILoading';


function Authentication({ history }) {
  const dispatch = useDispatch();

  const { credentials } = useParams();
  const showLoading = useSelector(states => states.authenticateStore.loading);

  useEffect(() => {
    if (credentials) {
      dispatch(startAuthenticationAction(credentials, history));
    }
  }, [credentials, dispatch, history]);

  return (
    <UILoading show={showLoading} />
  );
}

export default withRouter(Authentication);
