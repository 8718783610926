import axios from 'axios';
import { getToken } from '../utils/utils';

const URL = process.env.REACT_APP_API_URL;
const AUTHORIZATION = process.env.REACT_APP_TOKEN_AUTHORIZATION;

export const authGateway = axios.create({
  baseURL: URL,
  headers: {
    Authorization: AUTHORIZATION,
  },
  timeout: 60000,
});

export const gateway = axios.create({
  baseURL: URL,
  timeout: 60000,
});

/*
* Interceptador para setar o token em cada requisição a partir do gateway.
 */
gateway.interceptors.request.use(
  (config) => {
    const newConfig = config;
    const token = getToken();
    if (token) {
      newConfig.headers.Authorization = token;
    }
    return newConfig;
  },
);
