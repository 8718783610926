import makeStyles from '@material-ui/core/styles/makeStyles';
import { WARM_GRAY } from '../../utils/colors/colors.contants';

export default makeStyles({
  headerContainer: {
    padding: '1.5% 3.9%',
    
    
    
  },
  headerVerticalLine: {
    borderRight: `1px solid ${WARM_GRAY}`,
    height: '52px',
    '@media screen and (max-width: 666px)': {
      height: '32px',
    },
  },
  headerLogoDourado: {
    width: 'auto',
    maxWidth: '145px',
    height: 'auto',
    maxHeight: '65px',
    '@media screen and (max-width: 666px)': {
      width: '88.7px',
      height:'27.4',
    },
  },
  headerLogoCadeado: {
    width: 'auto',
    maxWidth: '119px',
    height: 'auto',
    '@media screen and (max-width: 666px)': {
      width: '72.6px',
      height:'28,2',
    },
  },
  colorLink: {
    color: 'inherit',
       
              
              
         
        
  },
  divLinks: {
    paddingLeft: '16px',
    '@media screen and (max-width: 600px)': {
      paddingLeft:'9px',
    },
  },
    
  
  grid__pointer: {
    cursor: 'pointer',
  },

 
   
    
    
  
});
