import makeStyles from '@material-ui/core/styles/makeStyles';
import { NAVY_BLUE, PLANTIUM } from '../../../utils/colors/colors.contants';

export default makeStyles({
  tableRoot: {
    width: '100%',
    margin: '0rem 1.625rem 1.625rem 1.625rem',
    borderRadius: '8px 8px 0px 0px',
  },
  tableContainer: {
    borderRadius: '8px 8px 0px 0px',
    maxHeight: '440px',
  },
  tableColumnHeader: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: PLANTIUM,
      '@media screen and (max-width: 600px)': {
        padding: '8px',
      },
      '& p': {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: `${NAVY_BLUE}`,
        '@media screen and (max-width: 600px)': {
          fontSize: '0.875rem',
        },
      },
    },
  },
  tableCell: {
    '& .MuiTableCell-body': {
      wordWrap: 'break-word',
      '@media screen and (max-width: 600px)': {
        maxWidth: '50px',
        padding: '8px',
      },
      '& p': {
        fontSize: '0.875rem',
        textOverflow: 'ellipsis',
        '@media screen and (max-width: 600px)': {
          fontSize: '0.813rem',
        },
      },
    },
  },
});
