export const SET_TITULOS = '@home/SET_TITULOS';
export const SET_DEVEDOR = '@home/SET_DEVEODR';
export const SET_PAGE_VIEW_ACTIONS = '@home/SET_PAGE_VIEW_ACTIONS';
export const SET_GLOBALS = '@home/SET_GLOBALS';
export const SET_CAPA = '@home/SET_CAPA';
export const SET_PARAMETRO_GERAIS = '@home/SET_PARAMETRO_GERAIS';
export const SET_DADOS_CREDOR = '@home/DADOS_CREDOR';
export const SET_OUTRAS_DIVIDAS = '@home/SET_OUTRAS_DIVIDAS';
export const SET_DADOS_RECIBO = '@home/SET_DADOS_RECIBO';
export const SET_FALE_CONOSCO = '@home/SET_FALE_CONOSCO';
export const SET_DADOS_ENVIAR_COMPROVANTE = '@home/SET_DADOS_ENVIAR_COMPROVANTE';
export const RESET_DADOS_ENVIAR_COMPROVANTE = '@home/RESET_DADOS_ENVIAR_COMPROVANTE';
export const CLEAR_STATE_FALE_CONOSCO = '@home/CLEAR_STATE_FALE_CONOSCO';

/* SAGA */
export const API_GET_TITULOS = '@home/API_GET_TITULOS';
export const API_GET_DADOS_CREDOR = '@home/API_GET_DADOS_CREDOR';
export const API_GET_DEVEDOR = '@home/API_GET_DEVEDOR';
export const API_GET_PARAMETROS_GERAIS = '@home/API_GET_PARAMETROS_GERAIS';
export const API_GET_OUTRAS_DIVIDAS = '@home/API_GET_OUTRAS_DIVIDAS';
export const API_GET_LOGO_CLIENTE = '@home/API_GET_LOGO_CLIENTE';
export const API_GET_DADOS_RECIBO = '@home/API_GET_DADOS_RECIBO';
export const API_GET_BOLETO_ACORDO = '@home/GET_BOLETO_ACORDO';
export const API_GET_PIX_ACORDO = '@home/GET_PIX_ACORDO';
export const API_POST_COMPROVANTE_EMAIL = '@home/API_POST_COMPROVANTE_EMAIL';
export const API_POST_ENVIAR_EMAIL_FALE_CONOSCO = '@home/API_POST_ENVIAR_EMAIL_FALE_CONOSCO';
