import React from 'react';
import Grow from '@material-ui/core/Grow';

function GrowUI({ children, loading, timeoutEffect }) {
  return (
    <Grow
      in={!loading}
      style={{ transformOrigin: '0 0 0' }}
      {...(!loading ? { timeout: timeoutEffect } : {})}
    >
      {children}
    </Grow>
  );
}
export default GrowUI;
