import React from 'react';
import clsx from 'clsx';
import {CircularProgress, FormControl, Input, InputLabel,} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import useStyles from './InputField.styles';
import TooltipUI from '../Tooltip/Tooltip';


function InputField({
                      label,
                      disabled = false,
                      isForm = false,
                      loading = false,
                      error = false,
                      errorMessage = '',
                      showToolTip = true,
                      maxLength = 150,
                      fullWidth = false,
                      labelProps,
                      tooltipProps,
                      inputProps,
                      icon,
                      formProps,
                      ...props
                    }) {
  const styles = useStyles();

  function getEndAdornment() {
    return loading ? <CircularProgress size={isForm ? 15 : 16}/> : null;
  }

  function getStartAdornment() {
    return icon
      ? (
        <Icon style={{paddingLeft: '2px', marginRight: '4px', color: error ? 'red' : 'inherit'}}>
          {icon}
        </Icon>
      ) : null;
  }

  function buildInputGroup() {
    return (
      <>
        {label && <InputLabel error={error} {...labelProps}>{label}</InputLabel>}
        <Input
          error={error}
          disabled={disabled || loading}
          autoComplete="nope"
          startAdornment={getStartAdornment()}
          endAdornment={getEndAdornment()}
          type="text"
          inputProps={{
            maxLength,
            ...inputProps,
          }}
          {...props}
        />
      </>
    );
  }

  function buildOutlinedGroup() {
    return (
      <FormControl
        {...formProps}
        error={error}
        fullWidth={fullWidth}
        className={clsx(styles.outlinedRoot, {[styles.loading]: loading})}
      >
        {buildInputGroup()}
      </FormControl>
    );
  }

  function buildFormInput() {
    return (
      <div
        {...formProps}
        className={
          clsx(styles.formRoot, {[styles.loading]: loading, [styles.fullWidth]: fullWidth})
        }
      >
        {buildInputGroup()}
      </div>
    );
  }

  function buildField() {
    const content = isForm ? buildFormInput() : buildOutlinedGroup();
    if (error && showToolTip) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    if (error && !showToolTip) {
      return (
        <div className={clsx(styles.formRoot, {[styles.loading]: loading, [styles.fullWidth]: fullWidth})}>
          {content}
          <div style={{color: '#ff000f', fontSize: '14px'}}>{errorMessage}</div>
        </div>
      );
    }
    return content;
  }


  return buildField();
}

export default InputField;
