import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';
import DialogActions from '@material-ui/core/DialogActions';
import useStyles from './comprovantePagamentoCartao.styles';
import imgRecibo from '../../../assets/Imagens/imagem-recibo.png';
import ButtonUI from '../Button/ButtonUI';
import {
  errosFormInitialState,
  getTopicName,
  formComprovanteSchema,
  buildSchemaValidate,
  atribuirErrosForm,
  getErrorCelular,
} from './comprovantePagamentoCartao.utils';
import { generateUniqId } from '../../../utils/utils';
import InputField from '../../Input';
import { setDadosEnviarComprovanteAction, resetDadosEnviarComprovanteAction } from '../../../store/home/home.store';
import { enviarComprovanteEmailAction } from '../../../store/home/home.saga';


function ComprovantePagamentoCartao({ dadosRecibo, open, onCloseHandler }) {
  const styles = useStyles();
  const dispatch = useDispatch();

  const dadosEnviarCompravante = useSelector(states => states.homeStore.dadosEnviarCompravante);
  const dialog = useSelector(states => states.homeStore.dadosEnviarCompravante.dialog);

  // Configura o estilo do icone de sucesso ou erro.
  const iconStyle = clsx({
    [styles.dialog__icon]: true,
    [styles.dialog__icon__sucess]: dialog.isSucess,
    [styles.dialog__icon__error]: !dialog.isSucess,
  });

  // States que indicam em qual parte do comprovante ele está.
  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const [isSecondScreen, setIsSecondScreen] = useState(false);
  const [isThirdScreen, setIsThirdScreen] = useState(false);

  const configureScreenState = useCallback((first, second, third) => {
    setIsFirstScreen(first);
    setIsSecondScreen(second);
    setIsThirdScreen(third);
  }, [setIsFirstScreen, setIsSecondScreen, setIsThirdScreen]);

  const [hasErros, setHasErros] = useState(false);

  // State responsavel pelas mensagens e erros do form.
  const [errosForm, setErrosForm] = useState(errosFormInitialState);

  // Função que vai resetar todos os erros dos campos.
  // Usada nas chamadas que fecham o dialog.
  const resetAllFormStates = useCallback(() => {
    setErrosForm({
      ...errosForm,
      emailDestinatarioDevedor: {
        message: '',
        error: false,
      },
      ddd: {
        message: '',
        error: false,
      },
      telefone: {
        message: '',
        error: false,
      },
    });
  }, [setErrosForm, errosForm]);

  // Clique que controla o state do componente, indicando se vai para o formulario.
  // Também limpa os campos com erros.
  const onClickEnviarEmailHandler = useCallback(() => {
    resetAllFormStates();
    configureScreenState(!isFirstScreen, !isSecondScreen);
  }, [configureScreenState, isFirstScreen, isSecondScreen, resetAllFormStates]);

  // Atribui valor aos campos do formulário.
  const onChangeFields = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setDadosEnviarComprovanteAction(name, value));
  }, [dispatch]);

  // Atribui valor para os campos do DDD e Celular
  const onChangeCelular = useCallback((event) => {
    const { name, value } = event.target;
    const numericValue = value.replace(/\D/g, '');
    dispatch(setDadosEnviarComprovanteAction(name, numericValue));
  }, [dispatch]);

  // Limpa os erros dos campos do formulario.
  const onFocusFields = useCallback((event) => {
    const { name } = event.target;
    setErrosForm({
      ...errosForm,
      [name]: {
        error: false,
      },
    });
  }, [setErrosForm, errosForm]);

  // Limpa os erros dos campos DDD+Celular.
  const onFocusCelular = useCallback(() => {
    setErrosForm({
      ...errosForm,
      ddd: {
        message: '',
        error: false,
      },
      telefone: {
        message: '',
        error: false,
      },
    });
  }, [setErrosForm, errosForm]);

  // Função que fecha o componente do comprovante.
  // Reseta os erros dos campos.
  // Reseta o valor dos campos.
  const onCloseComprovante = useCallback(() => {
    onCloseHandler();
    resetAllFormStates();
    dispatch(resetDadosEnviarComprovanteAction());
    configureScreenState(true, false, false);
  }, [resetAllFormStates, onCloseHandler, configureScreenState, dispatch]);

  const onClickOkSucessOrErrorHandler = useCallback(() => {
    resetAllFormStates();
    dispatch(resetDadosEnviarComprovanteAction());
    configureScreenState(true, false, false);
  }, [resetAllFormStates, dispatch, configureScreenState]);

  // Clique que faz a chamada para o serviço.
  // Validação do formulário.
  const onClickEnviarHandler = useCallback(async () => {
    const schemaToValidate = buildSchemaValidate(dadosEnviarCompravante);
    setHasErros(false);
    try {
      await formComprovanteSchema.validate(schemaToValidate, { abortEarly: false });
      if (!hasErros) {
        dispatch(enviarComprovanteEmailAction());
      }
    } catch (e) {
      setHasErros(true);
      atribuirErrosForm(e, errosForm);
      setErrosForm({ ...errosForm });
    }
  }, [dadosEnviarCompravante, setErrosForm, errosForm, hasErros, setHasErros, dispatch]);

  // Constrói os botões embaixo do comprovante.
  const buildButtonsFooter = useCallback((labelBtn1, labelBtn2, handlerBtn1, handlerBtn2, isBtnIcon = false) => (
    <div className={styles.div__button}>
      <ButtonUI label={labelBtn1} onClick={handlerBtn1} variant="secondary" icon={isBtnIcon ? <EmailIcon /> : null} />
      <ButtonUI label={labelBtn2} onClick={handlerBtn2} variant="dialog" />
    </div>
  ), [styles]);

  // UseEffect responsável por posicionar o usuário na tela de confirmação do envio.
  useEffect(() => {
    if (dialog.open) {
      configureScreenState(false, false, true);
    }
  }, [dialog, configureScreenState]);

  // Constrói cada linha do recibo com seus respectivos valores.
  const buildLinesRecibo = () => dadosRecibo.map((value, idx) => (
    <div className={styles.div__container} key={generateUniqId()}>
      <Typography component="p" className={styles.div__item__container}>
        {getTopicName(idx)}
      </Typography>
      {idx === 3 || idx === 4 ? (
        <Typography component="p" className={styles.div__item__container_bold}>
          {value}
        </Typography>
      ) : (
        <Typography component="p" className={styles.div__item__container}>
          {value}
        </Typography>
      )}
    </div>
  ));

  // Constroí o recibo.
  const buildReciboComponent = () => (
    <>
      {buildLinesRecibo()}
      {buildButtonsFooter('ENVIAR POR E-MAIL', 'OK', onClickEnviarEmailHandler, onCloseComprovante, true)}
    </>
  );

  // Constrói o formulário.
  const buildFormEmailComponent = () => (
    <>
      <div className={styles.div__form__root}>
        <div className={styles.div__form__fields}>
          <InputField
            fullWidth
            isForm
            label="E-mail:"
            name="emailDestinatarioDevedor"
            showToolTip={false}
            value={dadosEnviarCompravante.emailDestinatarioDevedor}
            maxLength={70}
            error={errosForm.emailDestinatarioDevedor.error}
            errorMessage={errosForm.emailDestinatarioDevedor.message}
            icon={<EmailIcon />}
            onChange={onChangeFields}
            onFocus={onFocusFields}
          />
        </div>
        <div className={styles.div__form__root__celular}>
          <div className={styles.input__celular__dd}>
            <InputField
              isForm
              label="DDD:"
              name="ddd"
              showToolTip={false}
              value={dadosEnviarCompravante.ddd}
              maxLength={2}
              error={getErrorCelular(errosForm.ddd.error, errosForm.telefone.error)}
              errorMessage={getErrorCelular(errosForm.ddd.message, errosForm.telefone.message)}
              onChange={onChangeCelular}
              onFocus={onFocusCelular}
            />
          </div>
          <div className={styles.input__celular__numero}>
            <InputField
              fullWidth
              isForm
              label="Celular:"
              name="telefone"
              showToolTip={false}
              value={dadosEnviarCompravante.telefone}
              maxLength={9}
              error={getErrorCelular(errosForm.ddd.error, errosForm.telefone.error)}
              onChange={onChangeCelular}
              onFocus={onFocusCelular}
            />
          </div>
        </div>
      </div>
      {buildButtonsFooter('Cancelar', 'Enviar', onClickEnviarEmailHandler, onClickEnviarHandler)}
    </>
  );

  // Constrói a tela de sucesso ou erro.
  const buildFinalizarEnvioComponent = () => (
    <>
      <div className={iconStyle}>
        {dialog.isSucess ? <CheckCircleIcon /> : <HighlightOffIcon />}
      </div>
      <div className={styles.dialog__textContainer}>
        <Typography component="p">
          {dialog.contentText}
        </Typography>
      </div>
      <DialogActions className={styles.dialog__actions}>
        <ButtonUI label="OK" variant="dialog" onClick={onClickOkSucessOrErrorHandler} />
      </DialogActions>
    </>
  );

  return (
    <div>
      <Dialog onClose={onCloseComprovante} open={open} className={styles.dialog__root}>
        <MuiDialogTitle disableTypography className={styles.dialog__titleRecibo}>
          <img src={imgRecibo} alt="" className={styles.dialog__titleRecibo__img} />
          <Typography variant="h6" className={styles.dialog__titleRecibo__typography}>Comprovante da Transação</Typography>
        </MuiDialogTitle>
        {isFirstScreen ? buildReciboComponent() : null}
        {isSecondScreen ? buildFormEmailComponent() : null}
        {isThirdScreen ? buildFinalizarEnvioComponent() : null}
      </Dialog>
    </div>
  );
}

export default ComprovantePagamentoCartao;
