import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import useStyles from './ButtonUI.styles';


function ButtonUI({
  label, disabled, onClick, variant, icon,
}) {
  if (!['primary', 'secondary', 'dialog', 'arrow'].includes(variant)) {
    throw new Error('Invalid variant ButtonUI. Valid is "primary", "secundary"');
  }

  const styles = useStyles();

  function buildButton() {
    return (
      <Button
        disabled={disabled}
        variant="outlined"
        className={clsx(styles[variant])}
        onClick={onClick}
      >
        {icon && (
          <div className={styles.div__icon}>
            {icon}
          </div>
        )}
        {label}
      </Button>
    );
  }

  function buildComponent() {
    if (variant === 'arrow') {
      return (
        <div className={styles.div__arrowBtn} onClick={onClick}>
          <i className="fas fa-chevron-left" />
          {buildButton()}
        </div>
      );
    }
    return buildButton();
  }

  return buildComponent();
}

export default ButtonUI;
