import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import homeReducer from './store/home/home.store';
import homeSaga from './store/home/home.saga';
import autenticateReducer from './store/autenticacao/autenticacao.store';
import autenticateSaga from './store/autenticacao/autenticacao.saga';

const reducers = combineReducers({
  authenticateStore: autenticateReducer,
  homeStore: homeReducer,
});

const sagaMiddleware = createSagaMiddleware();


const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware),
);

sagaMiddleware.run(autenticateSaga);
sagaMiddleware.run(homeSaga);

const application = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(application, document.getElementById('root'));

serviceWorker.unregister();
