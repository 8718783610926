import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import useStyles from './sobre.styles';
import imagemRelogio from '../../../assets/Imagens/imagem-relogio.png';
import imagemEscudo from '../../../assets/Imagens/imagem-escudo.png';
import imagemCarteira from '../../../assets/Imagens/imagem-carteira.png';
import Topico from '../../UI/Topico/Topico';
import ImagemTopicModel from '../../../models/ImagemTopicModel';
import {
  CONFIAVEL_TEXT,
  CONFIAVEL_TITLE,
  DESCONTO_TEXT,
  DESCONTO_TITLE,
  FACILIDADE_TEXT,
  FACILIDADE_TITLE,
  TITULO_FACIL,
  TITULO_NEGOCIAR,
} from './sobre.constants';
import imagemSteps from '../../../assets/Imagens/imagem-steps.png';
import SubTitle from '../../UI/Titles/SubTitle';
import PaperUI from '../../UI/Paper/PaperUI';
import FadeUI from '../../UI/Transitions/FadeUI';

const topics = [
  new ImagemTopicModel(1, imagemEscudo, 'Confiável', '51px', '51px', CONFIAVEL_TITLE, CONFIAVEL_TEXT, 1000),
  new ImagemTopicModel(2, imagemCarteira, 'Desconto', '47px', '47px', DESCONTO_TITLE, DESCONTO_TEXT, 2000),
  new ImagemTopicModel(3, imagemRelogio, 'Facilidade', '57px', '51px', FACILIDADE_TITLE, FACILIDADE_TEXT, 3000),
];

function Sobre() {
  const styles = useStyles();
  const loading = useSelector(states => states.homeStore.actionsPageView.loadingHome);
  return (
    <PaperUI>
      <Grid container item lg={12} md={12} sm={12}>
        <SubTitle title={TITULO_NEGOCIAR} />
        <Grid container justify="space-between" alignItems="flex-start" className={styles.topicsContainerItem}>
          {topics.map(topic => (
            <Grid key={topic.id} container item lg={4} md={4} sm={12} xs={12}>
              <Topico
                src={topic.scr}
                alt={topic.alt}
                imgMaxWidth={topic.imgMaxWidth}
                imgHeight={topic.imgHeight}
                title={topic.title}
                text={topic.text}
                timeoutEffect={topic.timeoutEffect}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <div style={{ padding: '16px' }}>
        <SubTitle title={TITULO_FACIL} />
      </div>
      <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12} xs={12} className={styles.stepsContainer}>
        <FadeUI loading={loading} timeoutEffect={2000}>
          <img
            className={styles.stepsImg}
            src={imagemSteps}
            alt="Veja como é facil negociar"
          />
        </FadeUI>
      </Grid>
    </PaperUI>
  );
}

export default memo(Sobre);
