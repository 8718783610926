import React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';

import useStyles from './RadioField.styles';

function RadioField({
  value, label, formProps, ...props
}) {
  const styles = useStyles();
  return (
    <FormControlLabel
      value={value}
      classes={{ root: styles.root }}
      control={(
        <Radio
          {...props}
          color="primary"
          classes={{ checked: styles.checked }}
        />
)}
      label={label}
      {...formProps}
    />
  );
}

export default React.memo(RadioField);
