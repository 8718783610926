import makeStyles from '@material-ui/core/styles/makeStyles';
import { NAVY_BLUE, PLANTIUM } from '../../../utils/colors/colors.contants';

export default makeStyles({
  tableRowContainerTitle: {
    '& .MuiTableCell-body': {
      padding: '8px 16px',
    },
  },
  tableRowContainer: {
    '& .MuiTableCell-sizeSmall': {
      padding: '4px 0px',
    },
  },
  tableContainer: {
    maxHeight: '440px',
  },
  tableheaderContainer: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: PLANTIUM,
      '& p': {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: `${NAVY_BLUE}`,
        '@media screen and (max-width: 600px)': {
          fontSize: '0.875rem',
        },
      },
    },
  },
  subTableContainer: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  subTableTitle: {
    fontSize: '0.875rem',
    fontWeight: '600',
  },
});
