import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router';
import logoGrejo from '../../assets/Logos/logo-grejo.png';
import logoCadeado from '../../assets/Logos/logo-acordo-seguro-cadeado.png';
import useStyles from './header.styles';
import HeaderContainer from '../UI/Background/HeaderConainer';

import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import {
  FACEBOOK_LINK, LINKEDIN_LINK, INSTAGRAM_LINK,
} from './header.constants';


function Header() {
  const styles = useStyles();
  const history = useHistory();

  const onClickLogoAbe = useCallback(() => {
    if (window.location.pathname === '/') {
      window.location.reload();
    } else {
      history.replace('/');
    }
  }, [history]);

  return (
    <HeaderContainer>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        className={styles.headerContainer}
      >
        <Grid
          direction="row"
          alignItems="center"
          justify="flex-start"
          container
          item
          lg={6}
          md={6}
          sm={8}
          xs={8}
          spacing={2}
        >
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <img
              className={styles.headerLogoDourado}
              src={logoGrejo}
              alt="Abe"
            />
          </Grid>
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <div className={styles.headerVerticalLine} />
          </Grid>
          <Grid item className={styles.grid__pointer} onClick={onClickLogoAbe}>
            <img
              className={styles.headerLogoCadeado}
              src={logoCadeado}
              alt="Acordo Seguro"
            />
          </Grid>
          </Grid>
          <Grid 
          direction="row"
          alignItems="center"
          justify="flex-end"
          display="flex"
          container
          item
          lg={6}
          md={6}
          sm={4}
          xs={4}
         
          
        >
          
           <Grid item >    
           
          <a className={styles.colorLink} href={INSTAGRAM_LINK} rel="noopener noreferrer" target="_blank">
          <InstagramIcon />
          </a>
</Grid>

          <Grid item className={styles.divLinks}>
          <a className={styles.colorLink} href={LINKEDIN_LINK} rel="noopener noreferrer" target="_blank">
            <LinkedInIcon />
          </a>
</Grid>

          <Grid item className={styles.divLinks}>
            <a className={styles.colorLink} href={FACEBOOK_LINK} rel="noopener noreferrer" target="_blank">
            <FacebookIcon />
            </a>
            </Grid>
            </Grid>
          </Grid>
          
      
    </HeaderContainer>
  );
}

export default Header;
