import React, { createContext } from 'react';
import { applyMiddleware, createStore } from 'redux';
import { createDispatchHook, createSelectorHook, Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import reducer from './negociar.store';
import watchNegociar from './negociar.saga';

export const negociarContext = createContext();
export const useNegociarSelector = createSelectorHook(negociarContext);
export const useNegociarDispatch = createDispatchHook(negociarContext);

export default function NegociarProivider({ children }) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(reducer, applyMiddleware(sagaMiddleware));
  sagaMiddleware.run(watchNegociar);

  return (
    <Provider context={negociarContext} store={store}>
      {children}
    </Provider>
  );
}
