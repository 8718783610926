export const BLACK_TWO = '#000000';
export const NAVY_BLUE = '#14213d';
export const WARM_GRAY = '#707070';
export const GRAY_SHADOW = '#ababab';
export const PURE_GRAY = '#c0c0c0';
export const WHITE_ELEMENTARY = '#e1e1e1';
export const VERY_LIGHT_GRAY = '#e5e5e5';
export const PLANTIUM = '#e4e5e5';
export const WHITE = '#ffffff';
export const WHITE_SMOKE = '#f6f6f6';
export const BOTTICELLI = '#c8d1e2';
export const BLUE = '#034ddc';
export const ORANGE_PRIMARY = '#fca311';
export const SHADE_GRAY = '#48484820';
export const ERROR = '#e91b1b';
export const BRIGHT_RED = '#ff000f';
export const ACCEPT_GREEN = '#35c399';
