import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import useStyles from './dialogUI.styles';
import ButtonUI from '../Button/ButtonUI';
import PaperUI from '../Paper/PaperUI';


function DialogUI({
  contentText = '',
  infoContentText = 'Ops!',
  confirmButtonText = 'OK',
  isSucess = false,
  open = false,
  onCloseHandler,
}) {
  const styles = useStyles();

  const iconStyle = clsx({
    [styles.dialog__icon]: true,
    [styles.dialog__icon__sucess]: isSucess,
    [styles.dialog__icon__error]: !isSucess,
  });

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCloseHandler}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.dialog__root}
      >
        <PaperUI>
          <div className={iconStyle}>
            {isSucess ? <CheckCircleIcon /> : <InfoIcon />}
          </div>
          <div className={styles.dialog__textContainer}>
            <Typography component="h2">
              {infoContentText}
            </Typography>
            <Typography component="p">
              {contentText}
            </Typography>
          </div>
          <DialogActions className={styles.dialog__actions}>
            <ButtonUI label={confirmButtonText} variant="dialog" onClick={onCloseHandler} />
          </DialogActions>
        </PaperUI>
      </Dialog>
    </div>
  );
}

export default DialogUI;
