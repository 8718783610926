import React, { useCallback, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from './acordionUI.makeStyles';
import useStyles from './acordionUI.styles';


export default function AcordionUI({ panelId, acordionTitle, acordionText }) {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  const onChangeHandler = useCallback((event, newExpanded) => {
    setExpanded(newExpanded);
  }, [setExpanded]);

  return (
    <div className={styles.div__root} >
      <Accordion square expanded={expanded} onChange={onChangeHandler}>
        <AccordionSummary id={panelId}>
          <Typography className={styles.typography} component="p">{acordionTitle}</Typography>
          <div>
            <ExpandMoreIcon />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="p" className={styles.acordionText}>
            {acordionText}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
