export const TOTAL_TITLE = 'TOTAL';
export const RESUMO_TITLE = 'RESUMO';
export const VALOR_PRINCIPAL_TITLE = 'VALOR PRINCIPAL:';
export const OUTROS_TITLE = 'OUTROS:';
export const OUTROS_SUB_TITLE = '(Protesto/Multa/Desconto)';
export const JUROS_E_DESPESAS_TITLE = 'JUROS E DESPESAS:';
export const HONORARIOS_TITLE = 'HONORÁRIOS:';
export const NEGOCIAR_BUTTON_LABEL = 'NEGOCIAR';
export const RESUMO_BUTTON_LABEL = 'RESUMO';
export const IMPRIMIR_BOLETO_LABEL = 'IMPRIMIR BOLETO';
export const VER_COMPROVANTE_LABEL = 'VER COMPROVANTE';
export const IMPRIMIR_QR_CODE_LABEL = 'IMPRIMIR QR Code';
export const TITLE_FORMA_PAGAMENTO = 'ESCOLHA SUA FORMA DE PAGAMENTO:';
export const BLANK = ' ';
