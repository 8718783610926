export const TITLE_FAQ = 'Tire suas Dúvidas';

export const OQUE_EH_PERGUNTA = 'O que é o Acordo Seguro?';
export const OQUE_EH_RESPOSTA = 'O Acordo Seguro é um serviço 100% digital oferecido em parceria com alguns de nossos clientes para negociação de dívidas online.';
export const CONFIAVEL_PAGAR_PERGUNTA = 'É confiável pagar pelo Acordo Seguro?';
export const CONFIAVEL_PAGAR_RESPOSTA = 'Sim. Como o próprio nome já diz, pagar a sua dívida através do site Acordo Seguro é muito seguro e confiável. Cada usuário recebe um acesso exclusivo com um prazo de expiração definido. O acesso é realizado através de um link que utiliza um rigoroso critério de segurança (https) e oferece uma conexão totalmente segura, onde suas informações (por exemplo, senhas ou número de cartão de crédito) permanecem privadas quando são enviadas para o site.';
export const NAO_ACESSAR_PERGUNTA = 'Por que não consigo acessar o site Acordo Seguro?';
export const NAO_ACESSAR_RESPOSTA = 'Para acessar o site é necessário estar conectado à internet do seu smartphone ou computador. Se estiver tudo certo com a sua internet, verifique se o link que você está tentando acessar é o mesmo recebido por e-mail ou mensagem de texto em seu celular. Se ainda assim não conseguir acessar as informações do site, é provável que o seu link de acesso tenha expirado.';
export const COMO_FALAR_PERGUNTA = 'Como falar com o Acordo Seguro?';
export const COMO_FALAR_RESPOSTA = 'Ficou com alguma dúvida e não encontrou o que precisa? Sem problemas! Fale com a gente pelo e-mail atendimento@acordoseguro.com.br ou então envie sua mensagem diretamente pelo Fale Conosco.';


export const ONDE_CONSULTAR_DETALHE_PERGUNTA = 'Onde posso consultar os detalhes da minha dívida?';
export const ONDE_CONSULTAR_DETALHE_RESPOSTA = 'Você pode verificar os débitos referentes ao valor principal da sua dívida ao selecionar a opção “Títulos“ da página principal. Também é possível conferir todos os acréscimos e descontos de valores de forma simples e transparente no “Resumo“ da dívida.';
export const CONTRA_PROPOSTA_PERGUNTA = 'Posso fazer uma contraproposta?';
export const CONTRA_PROPOSTA_RESPOSTA = 'Não, o site Acordo Seguro já oferece as melhores condições disponibilizadas pelos nossos parceiros para a negociação da sua dívida.';
export const NAO_RECONHECO_DIVIDA_PERGUNTA = 'Não reconheço essa dívida. E agora?';
export const NAO_RECONHECO_DIVIDA_RESPOSTA = 'As informações disponíveis em nosso site estão sempre atualizadas de acordo com os dados fornecidos pelos nossos parceiros. Caso já tenha regularizado o seu débito ou não reconheça a dívida, pedimos que entre em contato pelo e-mail atendimento@acordoseguro.com.br ou através dos canais de atendimento disponibilizados no site.';
export const POSSO_NEGOCIAR_QQR_MOMENTO_DIVIDA_PERGUNTA = 'Posso negociar a minha dívida no site a qualquer momento?';
export const POSSO_NEGOCIAR_QQR_MOMENTO_DIVIDA_RESPOSTA = 'Não. As condições exclusivas oferecidas pelo site Acordo Seguro são por tempo limitado e podem expirar dentro de poucos dias após o recebimento da oferta. Por isso, não perca a oportunidade e negocie a sua dívida o quanto antes com os melhores descontos';


export const COMO_PAGAR_DIVIDA_PERGUNTA = 'Como posso pagar a minha dívida?';
export const COMO_PAGAR_DIVIDA_RESPOSTA = 'Pensando nas condições mais favoráveis para o seu bolso, o pagamento pode ser feito com PIX, boleto bancário ou cartão de crédito, parcelado ou à vista, variando de acordo com as regras de cada parceiro. \n'
  + '\n'
  + 'Se optar pelo pagamento com PIX, você poderá gerar o QR Code contendo as informações do pagamento e salvá-lo ou imprimi-lo para pagar no banco de sua preferência até a data de vencimento. \n'
  + '\n'
  + 'Se optar pelo pagamento com boleto, você poderá gerar o primeiro boleto pelo site Acordo Seguro e pagar no banco de sua preferência até a data de vencimento. No caso de várias parcelas, após a confirmação do pagamento do primeiro boleto, os demais serão encaminhados por e-mail na medida em que os pagamentos forem sendo realizados. \n'
  + '\n'
  + 'Se optar pelo acordo com o cartão de crédito, o pagamento será confirmado somente após a aprovação da operadora de seu cartão.';
export const NAO_GERAR_BOLETO_PERGUNTA = 'Não consigo gerar o boleto. E agora?';
export const NAO_GERAR_BOLETO_RESPOSTA = 'Ao clicar para gerar o boleto pode ocorrer algum erro devido a uma instabilidade em nosso site ou na sua internet. Tente gerar o boleto novamente dentro de alguns instantes e se o problema persistir ou identificar alguma inconsistência nos dados do boleto, pedimos que entre em contato pelo e-mail atendimento@acordoseguro.com.br.';
export const GEREI_BOLETO_NAO_PAGOU_PERGUNTA = 'Gerei o boleto, tentei fazer o pagamento e não consegui. O que fazer?';
export const GEREI_BOLETO_NAO_PAGOU_RESPOSTA = 'Se você acabou de gerar o boleto, aguarde alguns minutos e tente realizar o pagamento novamente. Se este não for o seu caso, pedimos que entre em contato pelo e-mail atendimento@acordoseguro.com.br';
export const COMO_TIRAR_SEG_VIA_BOLETO_PERGUNTA = 'Como solicitar a 2ª via do boleto? ';
export const COMO_TIRAR_SEG_VIA_BOLETO_RESPOSTA = 'Você pode emitir a 2ª via do seu boleto localizando a opção “Imprimir Boleto” na página principal do site. Após expirado o prazo de acesso ao site, a 2ª via do boleto somente poderá ser solicitada através do e-mail atendimento@acordoseguro.com.br.';


export const COMO_SABER_ACORDO_APROVADO_PERGUNTA = 'Como saber se meu acordo foi aprovado?';
export const COMO_SABER_ACORDO_APROVADO_RESPOSTA = 'Se você escolheu a opção de pagamento com PIX e o QR Code foi apresentado no site Acordo Seguro, então seu acordo foi aprovado. \n'
  + '\n'
  + 'Se você escolheu a opção de pagamento com boleto bancário e o boleto foi apresentado no site Acordo Seguro, então seu acordo foi aprovado. \n'
  + '\n'
  + 'Se você escolheu a opção de pagamento com cartão e finalizou a operação com sucesso, então tudo indica que o acordo está bem encaminhado. O acordo para pagamento com cartão de crédito será de fato aprovado assim que recebermos uma confirmação da sua operadora de cartão. Esta operação poderá levar até 24 horas. \n'
  + '\n'
  + 'Lembre-se que a aprovação do acordo não significa que suas dívidas foram quitadas. A quitação irá ocorrer somente após o pagamento da(s) parcela(s).\n';
export const POSSO_CANCELAR_ACORDO_PERGUNTA = 'Posso cancelar o meu acordo?';
export const POSSO_CANCELAR_ACORDO_RESPOSTA = 'Você não conseguirá cancelar um acordo. No entanto, se as parcelas do acordo não forem quitadas nos vencimentos combinados, então o acordo será cancelado de forma automática.';
export const DIVIDA_VISIVEL_PERGUNTA = 'Fiz o acordo, mas minha dívida continua visível no site. Por quê?';
export const DIVIDA_VISIVEL_RESPOSTA = 'A realização de um acordo não significa que suas dívidas foram quitadas. A quitação irá ocorrer somente após o pagamento da(s) parcela(s) do acordo realizado. Portanto, é normal que sua dívida continue sendo exibida até a finalização do(s) pagamento(s).';
export const NAO_CONSEGUIR_CUMPRIR_PERGUNTA = 'Não consegui cumprir o meu acordo. É possível renegociá-lo?';
export const NAO_CONSEGUIR_CUMPRIR_RESPOSTA = 'Sim, mas essa opção não está disponível em nosso site. Caso precise renegociar a sua dívida, por favor, envie um e-mail para atendimento@acordoseguro.com.br.';
