import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  imgResponsive: {
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
  },
  gridContainerResumoDivida: {
    height: 'fit-content',
    '@media screen and (max-width: 667px)': {
      paddingLeft: 0,
    },
  },
  containerTabs: {
    padding: '0px 16px 0px 0px !important',
    '@media screen and (max-width: 959px)': {
      padding: '0px 0px 16px 0px !important',
    },
  },
  containerResumoDivida: {
    paddingTop: '0px !important',
    '@media screen and (max-width: 959px)': {
      padding: '0px !important',
    },
  },
  paperImg: {
    borderRadius: '0px',
  },
  grid__canaisAtendimento: {
    marginBottom: '1.03125rem',
  },
});
