import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useStyles from './titles.styles';

function SubTitle({ title, upperCase = false }) {
  const styles = useStyles();
  return (
    <Grid container item alignItems="center" justify="center" lg={12} md={12} sm={12} className={styles.subTitleContainer}>
      <Typography style={{ textTransform: upperCase ? 'upperCase' : 'inherit' }} component="p">
        {title}
      </Typography>
    </Grid>
  );
}

export default SubTitle;
