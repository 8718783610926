import React from 'react';
import Divider from '@material-ui/core/Divider';
import useStyles from './dividerUI.styles';

function DividerUI() {
  const styles = useStyles();
  return (
    <>
      <Divider variant="fullWidth" className={styles.divider} />
    </>
  );
}

export default DividerUI;
