import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import useStyles from './customLabel.styles';
import FadeUI from '../Transitions/FadeUI';

function CustomLabel({
  title, value, justify = 'flex-start', alignItems = 'flex-start',
}) {
  const loading = useSelector(states => states.homeStore.actionsPageView.loading);
  const styles = useStyles();
  return (
    <Grid container wrap="nowrap" justify={justify} alignItems={alignItems}>
      <Grid item className={styles.label_title}>
        <Typography component="p">{title}</Typography>
      </Grid>
      <Grid item className={styles.label_value}>
        <FadeUI loading={loading} timeoutEffect={400}>
          <Typography component="p">{value}</Typography>
        </FadeUI>
      </Grid>
    </Grid>
  );
}

export default CustomLabel;
