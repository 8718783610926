import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../UI/Titles/SubTitle';
import PaperUI from '../../UI/Paper/PaperUI';
import CustomLabel from '../../UI/Label/CustomLabel';
import DividerUI from '../../UI/divider/DividerUI';

function Credor({ dadosCredor }) {
  return (
    <PaperUI>
      <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12} xs={12}>
        <SubTitle title="CONSULTE AQUI OS DADOS DO SEU CREDOR" />
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomLabel justify="center" title="Nome: " value={dadosCredor.nome} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DividerUI />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomLabel justify="center" title="Endereço: " value={dadosCredor.endereco} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomLabel justify="center" title="Cidade: " value={dadosCredor.cidade} />
        </Grid>
      </Grid>
    </PaperUI>
  );
}

export default memo(Credor);
