import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK_TWO } from '../../../utils/colors/colors.contants';

export default makeStyles({
  label_title: {
    maxWidth: '100%',
    '& p': {
      fontSize: '1rem',
      color: BLACK_TWO,
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.875rem',
      },
    },
  },
  label_value: {
    marginLeft: '5px',
    maxWidth: '100%',
    '& p': {
      fontSize: '1rem',
      color: BLACK_TWO,
      '@media screen and (max-width: 600px)': {
        fontSize: '0.875rem',
      },
    },
  },
});
