export default class ImagemCardButton {
  constructor(srcImg, srcImgDisabled, title, alt, width, height) {
    this.srcImg = srcImg;
    this.srcImgDisabled = srcImgDisabled;
    this.title = title;
    this.alt = alt;
    this.width = width;
    this.height = height;
  }
}
