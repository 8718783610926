import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { NAVY_BLUE, WHITE } from '../../../utils/colors/colors.contants';

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    borderRadius: '27px',
    '&:not(:last-child)': {
      borderBottom: 0,
      borderRadius: '27px',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    borderRadius: '27px',
    '&$expanded': {
      minHeight: 56,
      backgroundColor: NAVY_BLUE,
      color: WHITE,
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
      width: '100%',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);
