import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  gridFieldsForm: {
    paddingBottom: '20px',
  },
  gridValidadeCvvForm: {
    paddingBottom: '20px',
    maxWidth: 'inherit',
  },
});
