import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import SubTitle from '../../UI/Titles/SubTitle';
import TableUI from '../../UI/Tabela/TableUI';
import TableHeaderModel from '../../../models/TableHeaderModel';
import {
  NUMERO_TITULO_HEADER, TIPO_TITULO_HEADER, VALOR_TITULO_HEADER, VENCIMENTO_TITULO_HEADER,
} from './titulos.constants';
import PaperUI from '../../UI/Paper/PaperUI';

const columns = [
  new TableHeaderModel('descricaoTipoTitulo', TIPO_TITULO_HEADER, 60, 'center'),
  new TableHeaderModel('numero', NUMERO_TITULO_HEADER, 20, 'center'),
  new TableHeaderModel('dataVencimento', VENCIMENTO_TITULO_HEADER, 35, 'center', 'date'),
  new TableHeaderModel('valorSaldoPrincipal', VALOR_TITULO_HEADER, 30, 'right', 'money'),
];

function Titulos({ titulos = [] }) {
  return (
    <PaperUI>
      <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12}>
        <SubTitle title="CONSULTE A RELAÇÃO DE TÍTULOS" />
      </Grid>
      <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12}>
        <TableUI columns={columns} rows={titulos} />
      </Grid>
    </PaperUI>
  );
}

export default memo(Titulos);
