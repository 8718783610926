import makeStyles from '@material-ui/core/styles/makeStyles';
import { BOTTICELLI, NAVY_BLUE } from '../../../utils/colors/colors.contants';


export default makeStyles({

  divRoot: {
    position: 'relative',
    height: '100vh',
    backgroundImage: `linear-gradient(white, ${BOTTICELLI})`,
    '@media screen and (max-height: 414px)': {
      height: 'calc(100vh + 10rem)',
    },
  },

  divRootContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  divHeaderContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    background: NAVY_BLUE,
    '@media screen and (min-width: 375px)': {
      height: '3rem',
    },
  },
  contentDivContainer: {
    textAlign: 'center',
    position: 'absolute',
    top: '30px',
    '& h1, h2': {
      fontWeight: 'bold !important',
      color: NAVY_BLUE,
      margin: 0,
    },
    '@media screen and (max-width: 599px)': {
      top: '50px',
    },
  },
  typograhpyFirstText: {
    display: 'block',
    fontSize: '6.7rem',
    '@media screen and (max-width: 599px)': {
      fontSize: '3rem',
    },
    '@media screen and (max-height: 414px)': {
      fontSize: '3rem',
    },
  },
  typograhpySecondText: {
    display: 'block',
    fontSize: '1.563rem',
    '@media screen and (max-width: 599px)': {
      fontSize: '1rem',
    },
  },
  verticalLine: {
    borderRight: '1px solid white',
    height: '40px',
    marginLeft: '8px',
    marginRight: '8px',
    '@media screen and (max-height: 667px)': {
      height: '28px',
    },
  },
  naoEncontradoImage: {
    width: 'auto',
    maxWidth: '170px',
    height: 'auto',
    display: 'inline-block',
    '@media screen and (min-height: 100px)': {
      width: '70px',
      height: '70px',
    },
    '@media screen and (min-height: 360px)': {
      width: '70px',
      height: '70px',
    },
    '@media screen and (min-width: 600px)': {
      width: '130px',
      height: '130px',
    },
  },
  acordoSeguroImage: {
    width: 'auto',
    maxWidth: '88px',
    height: 'auto',
    '@media screen and (max-height: 667px)': {
      width: '70',
      height: 'auto',
    },
  },
  abeImage: {
    width: 'auto',
    maxWidth: '95px',
    height: 'auto',
    '@media screen and (max-height: 667px)': {
      width: '80px',
      height: 'auto',
    },
  },

  contatosText: {
    color: NAVY_BLUE,
    margin: 0,
    marginBottom: '0.3rem',
    paddingTop: '2.5rem',
    '@media screen and (max-width: 599px)': {
      fontSize: '0.75rem',
    },
  },
  divContainerContatos: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginTop: '6px',
    '& h4, h6': {
      color: NAVY_BLUE,
      margin: '0 4px',
    },
  },
  divContainerTelefones: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    '& h4, h6': {
      color: NAVY_BLUE,
      margin: '0 4px',
    },
    fontWeight: 'bold',
    '@media screen and (max-width: 599px)': {
      display: 'block',
    },
  },
  infoContatosTitle: {
    fontWeight: 'bold',
    '@media screen and (max-width: 599px)': {
      fontSize: '0.75rem',
      paddingTop: '4px',
    },
  },
  infoContatosMarginText: {
    fontWeight: 'normal',
    marginLeft: '4px',
    '@media screen and (max-width: 599px)': {
      fontSize: '0.75rem',
      marginLeft: '4px',
    },
  },
  indicatorCustoLocalMobile: {
    display: 'none',
    '@media screen and (max-width: 599px)': {
      display: 'flex',
      marginLeft: '0px !important',
    },
  },
  infoContatosSeparator: {
    fontWeight: 'normal',
    marginLeft: '4px',
    '@media screen and (max-width: 599px)': {
      display: 'none',
    },
  },
  divTelefoneEcusto: {
    display: 'flex',
    '@media screen and (max-width: 599px)': {
      display: 'inline-flex',
      paddingTop: '8px',
    },
  },
  divIcons: {
    color: NAVY_BLUE,
    marginRight: '8px',
  },
  divIconTelefoneETexto: {
    display: 'flex',
    justifyContent: 'center',
  },
  divIconTelefone: {
    color: NAVY_BLUE,
    marginRight: '8px',
    paddingTop: '4px',
    '@media screen and (max-width: 599px)': {
      paddingTop: 0,
      '& i': {
        fontSize: '14px',
      },
    },
  },
  divIconWhatsApp: {
    color: NAVY_BLUE,
    marginRight: '8px',
    '& i': {
      fontSize: '22px',
    },
  },
});
