import makeStyles from '@material-ui/core/styles/makeStyles';
import { ACCEPT_GREEN, BRIGHT_RED } from '../../../utils/colors/colors.contants';

export default makeStyles({
  title__container: {
    paddingBottom: '7px',
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'center',
      textTransform: 'upperCase',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  typography__subTitle: {
    fontSize: '16px',
    textAlign: 'center',
    '& h3': {
      color: '#000000 !important',
    },
  },
  grid__inputFields: {
    marginTop: '38px',
  },
  areaSize: {
    '& textarea': {
      minHeight: '100px',
      fontSize: '16px',
      width: '100% !important ',
      padding: '8px !important',
    },
  },
  grid__button: {
    marginTop: '61px',
  },
  div__messageContainer: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      fontSize: 'larger',
      paddingRight: '4px',
    },
    '& p': {
      fontWeight: 'bold !important',
      fontSize: '16px',
    },
  },
  div__messageContainer_success: {
    color: ACCEPT_GREEN,
  },
  div__messageContainer_error: {
    color: BRIGHT_RED,
  },
});
