import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import TabModel from '../../models/TabModel';
import Header from '../Header/Header';
import TabCardUI from '../UI/Tabs/TabCardUI';
import useStyles from './home.styles';
import TabPanelUI from '../UI/Tabs/TabPanelUI';
import Sobre from './Sobre/Sobre';
import UILoading from '../UI/Loading/UILoading';
import Titulos from './Titulos/Titulos';
import Credor from './Credor/Credor';
import {
  getDadosCredorAction,
  getDevedorAction,
  getLogoClienteAction,
  getOutrasDividasAction,
  getParametrosGeraisAction,
  getTitulosAction,
} from '../../store/home/home.saga';
import Devedor from './Devedor/Devedor';
import ResumoDivida from './ResumoDivida/ResumoDivida';
import Dividas from './Dividas/Dividas';
import {
  setDadosCredorAction, setDevedorAction, setOutrasDividasAction, setTitulosAction,
} from '../../store/home/home.store';
import FadeUI from '../UI/Transitions/FadeUI';
import CanaisAtendimento from './CanaisAtendimento/CanaisAtendimento';
import Faq from './FAQ/Faq';
import BodyContainer from '../UI/Background/BodyContainer';
import FaleConosco from './FaleConosco/FaleConosco';
import { errorFaleConoscoInitialState, resetErrors } from './FaleConosco/faleConosco.utils';
import { getItemFromSession, saveObjectSessionStorage } from '../../utils/utils';
import Footer from '../Footer/Footer';

const tabItems = [
  new TabModel('tab-sobre', 0, 'SOBRE', 'scrollable-auto-tabpanel'),
  new TabModel('tab-titulos', 1, 'TÍTULOS', 'scrollable-auto-tabpanel'),
  new TabModel('tab-seus-dados', 2, 'SEUS DADOS', 'scrollable-auto-tabpanel'),
  new TabModel('tab-credor', 3, 'CREDOR', 'scrollable-auto-tabpanel'),
  new TabModel('tab-outras-dividas', 4, 'OUTRAS DÍVIDAS', 'scrollable-auto-tabpanel'),
  new TabModel('tab-faq', 5, 'PERGUNTAS FREQUENTES', 'scrollable-auto-tabpanel'),
  new TabModel('tab-fale-conosco', 6, 'FALE CONOSCO', 'scrollable-auto-tabpanel'),
];

function Home() {
  const dispatch = useDispatch();
  const styles = useStyles();

  const showLoading = useSelector(states => states.homeStore.actionsPageView.loading);
  const loadingHome = useSelector(states => states.homeStore.actionsPageView.loadingHome);
  const titulos = useSelector(states => states.homeStore.titulos);
  const capa = useSelector(states => states.homeStore.capaHome);
  const devedor = useSelector(states => states.homeStore.devedor);
  const resumoDivida = useSelector(states => states.homeStore.resumoDividas);
  const dadosCredor = useSelector(states => states.homeStore.dadosCredor);
  const outrasDividas = useSelector(states => states.homeStore.outrasDividas);
  const logoCliente = useSelector(states => states.homeStore.logoCliente.imagePreviewUrl);
  const idCliente = useSelector(states => states.homeStore.searchCriteria.idCliente);


  const reloadHome = getItemFromSession('reloadHome');


  const [activeTab, setActiveTab] = useState(0);
  const [errosFaleConosco, setErrosFaleConosco] = useState(errorFaleConoscoInitialState);

  // Carrega o conteúdo inicial.
  // Reseta os valores do storage referentes a forma pagamento.
  useEffect(() => {
    dispatch(getParametrosGeraisAction());
    saveObjectSessionStorage('imgQuality', null);
  }, [dispatch]);

  useEffect(() => {
    if (reloadHome === 'true') {
      window.location.reload();
      saveObjectSessionStorage('reloadHome', false);
    }
  }, [reloadHome]);

  // Carrega o Logo do Cliente
  useEffect(() => {
    if (idCliente) {
      dispatch(getLogoClienteAction());
    }
  }, [idCliente, dispatch]);

  const getTitulosHandler = useCallback(() => {
    dispatch(getTitulosAction());
  }, [dispatch]);

  const getDevedorHandler = useCallback(() => {
    dispatch(getDevedorAction());
  }, [dispatch]);

  const getDadosCredorHandler = useCallback(() => {
    dispatch(getDadosCredorAction());
  }, [dispatch]);

  const getOutrasDividasHandler = useCallback(() => {
    dispatch(getOutrasDividasAction());
  }, [dispatch]);

  const clearErrosFaleConosco = useCallback(() => {
    setErrosFaleConosco(resetErrors());
  }, [setErrosFaleConosco]);

  const clearAllStates = useCallback(() => {
    dispatch(setTitulosAction([]));
    dispatch(setDevedorAction({}));
    dispatch(setDadosCredorAction({}));
    dispatch(setOutrasDividasAction([]));
    clearErrosFaleConosco();
  }, [dispatch, clearErrosFaleConosco]);

  const changeActiveTabHandler = (tabActive) => {
    clearAllStates();
    if (tabActive === 1) {
      getTitulosHandler();
    }
    if (tabActive === 2) {
      getDevedorHandler();
    }
    if (tabActive === 3) {
      getDadosCredorHandler();
    }
    if (tabActive === 4) {
      getOutrasDividasHandler();
    }
    setActiveTab(tabActive);
  };

  return (
    <>
      <UILoading show={showLoading} />
      <Header />
      <BodyContainer>
        <Grid container className={styles.container}>
          <Grid item lg={8} md={8} sm={12} xs={12} className={styles.containerTabs}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Paper className={styles.paperImg}>
                <FadeUI loading={loadingHome} timeoutEffect={1000}>
                  <img
                    src={capa}
                    alt="Capa"
                    className={styles.imgResponsive}
                  />
                </FadeUI>
              </Paper>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TabCardUI parentStates={[activeTab, changeActiveTabHandler]} tabItens={tabItems}>
                <TabPanelUI index={tabItems[0].index} value={activeTab}>
                  <Sobre />
                </TabPanelUI>
                <TabPanelUI index={tabItems[1].index} value={activeTab}>
                  <Titulos titulos={titulos} />
                </TabPanelUI>
                <TabPanelUI index={tabItems[2].index} value={activeTab}>
                  <Devedor devedor={devedor} />
                </TabPanelUI>
                <TabPanelUI index={tabItems[3].index} value={activeTab}>
                  <Credor dadosCredor={dadosCredor} />
                </TabPanelUI>
                <TabPanelUI index={tabItems[4].index} value={activeTab}>
                  <Dividas outrasDividas={outrasDividas} loading={showLoading} />
                </TabPanelUI>
                <TabPanelUI index={tabItems[5].index} value={activeTab}>
                  <Faq />
                </TabPanelUI>
                <TabPanelUI index={tabItems[6].index} value={activeTab}>
                  <FaleConosco errosFaleConosco={errosFaleConosco} setErrosFaleConosco={setErrosFaleConosco} />
                </TabPanelUI>
              </TabCardUI>
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} className={styles.containerResumoDivida}>
            <Grid item>
              <ResumoDivida dividas={resumoDivida} />
            </Grid>
            <Grid item className={styles.grid__canaisAtendimento}>
              <CanaisAtendimento logoCliente={logoCliente} />
            </Grid>
          </Grid>
        </Grid>
      </BodyContainer>
      <Footer/>
    </>
  );
}

export default withRouter(Home);
