import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  BLACK_TWO, ERROR, NAVY_BLUE, WHITE,
} from '../../../utils/colors/colors.contants';

export default makeStyles({
  dialog__root: {
    '& .MuiDialog-paperWidthSm': {
      borderRadius: '1.125rem',
      width: '45rem',
      maxWidth: '53.125rem',
      maxHeight: '33.3125rem',
    },
  },
  dialog__titleRecibo: {
    backgroundColor: NAVY_BLUE,
    color: WHITE,
    display: 'flex',
    marginBottom: '3.125rem',
    paddingLeft: '10%',
  },
  dialog__titleRecibo__img: {
    maxWidth: '3.125rem',
    maxHeight: '3.125rem',
  },
  dialog__titleRecibo__typography: {
    alignSelf: 'center',
    boxSizing: 'border-box',
    paddingLeft: '1.375rem',
    textTransform: 'upperCase',
  },
  div__container: {
    display: 'flex',
    width: '100%',
    marginBottom: '0.75rem',
  },
  div__item__container: {
    width: '50%',
    color: BLACK_TWO,
    fontSize: '1rem',
    '&:first-child': {
      textAlign: 'left',
      textTransform: 'upperCase',
      paddingLeft: '3.75rem',
      '@media screen and (max-width: 600px)': {
        paddingLeft: '1.25rem',
      },
    },
    '@media screen and (max-width: 600px)': {
      fontSize: '0.75rem',
      paddingLeft: '0.375rem',
    },
  },
  div__item__container_bold: {
    width: '50%',
    color: BLACK_TWO,
    fontSize: '1rem',
    fontWeight: 'bold',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.75rem',
    },
  },
  div__button: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: '1.9375rem',
    marginBottom: '2.25rem',
    '@media screen and (max-width: 600px)': {
      display: 'block',
      padding: '0px 16px',
      '& button:first-child': {
        marginBottom: '0.875rem',
      },
    },
  },
  div__form__root: {
    margin: '0 4.3125rem',
    '@media screen and (max-width: 600px)': {
      margin: '0 1rem',
    },
  },
  div__form__fields: {
    marginBottom: '2rem',
  },
  div__form__root__celular: {
    display: 'flex',
    width: '100%',
  },
  input__celular__dd: {
    width: '20%',
    marginRight: '1.25rem',
  },
  input__celular__numero: {
    width: '80%',
  },
  dialog__icon: {
    display: 'inline-flex',
    width: '100%',
    maxHeight: '10.25rem',
    justifyContent: 'center',
    paddingTop: '2.1875rem',
  },
  dialog__icon__sucess: {
    '& .MuiSvgIcon-root': {
      fontSize: '5.5rem',
      color: 'green',
      '@media screen and (max-width: 600px)': {
        fontSize: '3rem',
      },
    },
  },
  dialog__icon__error: {
    '& .MuiSvgIcon-root': {
      fontSize: '5.5rem',
      color: ERROR,
      '@media screen and (max-width: 600px)': {
        fontSize: '3rem',
      },
    },
  },
  dialog__textContainer: {
    textAlign: 'center',
    color: NAVY_BLUE,
    '& p': {
      fontSize: '1.375rem',
    },
    '@media screen and (max-width: 600px)': {
      '& p': {
        fontSize: '0.875rem',
      },
      padding: '0 2.1875rem',
    },
  },
  dialog__actions: {
    justifyContent: 'center',
    padding: '4rem 0 2.1875rem 0',
    '@media screen and (max-width: 600px)': {
      width: '80%',
      margin: 'auto',
    },
  },
});
