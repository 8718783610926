import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK_TWO, VERY_LIGHT_GRAY, WHITE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  appBar: {
    marginTop: '-45px',
    width: '100%',
    '& .Mui-selected': {
      backgroundColor: '#14213d !important',
      color: WHITE,
      fontWeight: '500 !important',
      outline: 'none !important',
    },
    '& .MuiTabs-scrollButtonsDesktop': {
      backgroundColor: BLACK_TWO,
      color: WHITE,
      width: '10px',
    },
    '& .MuiTab-root': {
      padding: '0px 48.2px',
      textTransform: 'none',
      fontSize: '1rem',
      lineHeight: '1.19',
      fontWeight: 'normal',
      minHeight: '41px',
      width: '200px',
      maxWidth: 'auto',
      borderRadius: '28px 28px 0px 0px',
      backgroundColor: VERY_LIGHT_GRAY,
      opacity: 'inherit',
    },
    '& .MuiTabs-root': {
      minHeight: '41px',
      '@media screen and (max-width: 414px)': {
        marginTop: '1px',
      },
    },
    '& .MuiTab-wrapper': {
      fontSize: '14px !important',
      fontWeight: 'bold',
    },
  },
  tabSelectColor: {
    backgroundColor: 'initial',
  },
});
