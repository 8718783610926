import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './passoUI.styles';

function PassoUI({ numero, margin = 'bottom' }) {
  const styles = useStyles();
  const formatNumero = `Passo ${numero}:`;
  return (
    <div style={margin === 'bottom' ? { marginBottom: '11px' } : { marginRight: '20px' }}>
      <Typography className={styles.textPassos} component="p">{formatNumero}</Typography>
    </div>
  );
}

export default PassoUI;

