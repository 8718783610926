import React from 'react';
import Fade from '@material-ui/core/Fade';

function FadeUI({ children, loading, timeoutEffect = 1000 }) {
  return (
    <Fade
      in={!loading}
      style={{ transformOrigin: '0 0 0' }}
      {...(!loading ? { timeout: timeoutEffect } : {})}
    >
      {children}
    </Fade>
  );
}

export default FadeUI;
