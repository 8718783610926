import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  topicoImg: props => ({
    width: 'auto',
    maxWidth: props.imgMaxWidth,
    height: props.imgHeight,
    '@media screen and (max-width: 600px)': {
      maxWidth: 'auto',
    },
  }),
  topicoTitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.875rem',
    },
  },
  topicoSubTitle: {
    fontSize: '0.875rem',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.813rem',
    },
  },
  topicoContainerTopic: {
    margin: '0px !important',
  },
});
