import React from 'react';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import useStyles from './Tooltip.styles';

function TooltipUI({ children, error, ...props }) {
  const styles = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: clsx({ [styles.error]: error, [styles.fontSize]: true }) }}
      placement="bottom-start"
      {...props}
    >
      {children}
    </Tooltip>
  );
}

export default TooltipUI;
