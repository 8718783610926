import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import PaperUI from '../../UI/Paper/PaperUI';
import SubTitle from '../../UI/Titles/SubTitle';
import CustomLabel from '../../UI/Label/CustomLabel';
import DividerUI from '../../UI/divider/DividerUI';
import { formataCep, fromataTelefone } from '../../../utils/utils';

function Devedor({ devedor = {} }) {
  return (
    <PaperUI>
      <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12}>
        <SubTitle title="CONSULTE AQUI OS SEUS DADOS" />
      </Grid>
      <Grid container spacing={2}>
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="Nome: " value={devedor.nome} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="E-mail: " value={devedor.email} />
          </Grid>
        </Grid>
        <Grid container item spacing={2} lg={4} md={12} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="CPF/CNPJ: " value={devedor.cnpjCpf} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="Telefone: " value={fromataTelefone(devedor.ddd, devedor.telefone)} />
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <DividerUI />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <CustomLabel title="CEP: " value={formataCep(devedor.cep)} />
        </Grid>
        <Grid container item spacing={2} lg={8} md={12} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="Endereço: " value={devedor.endereco} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CustomLabel title="Bairro: " value={devedor.bairro} />
          </Grid>
        </Grid>
        <Grid container item spacing={2} lg={4} md={12} sm={12} xs={12}>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <CustomLabel title="Cidade: " value={devedor.cidade} />
          </Grid>
          <Grid item lg={12} sm={12} md={12} xs={12}>
            <CustomLabel title="Estado: " value={devedor.estado} />
          </Grid>
        </Grid>
      </Grid>
    </PaperUI>
  );
}

export default memo(Devedor);
