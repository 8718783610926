import { authGateway } from '../api.service';

const AUTH_URI = process.env.REACT_APP_AUTH_URI;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;


export const getAuthentication = credentials => authGateway.post(AUTH_URI, {}, {
  params: {
    grant_type: 'password',
    client_id: CLIENT_ID,
    username: credentials,
    password: credentials,
  },
});
