import makeStyles from '@material-ui/core/styles/makeStyles';
import { ERROR } from '../../utils/colors/colors.contants';

export default makeStyles({
  error: {
    backgroundColor: `${ERROR}`,
  },
  fontSize: {
    fontSize: '12px',
    margin: '5px',
  },
});
