import makeStyles from '@material-ui/core/styles/makeStyles';
import { NAVY_BLUE, WHITE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  cardContainer: {
    backgroundColor: NAVY_BLUE,
    borderRadius: '20px 20px 0px 0px',
    padding: '25px',
  },
  containerTitleTextLabel: {
    '& p': {
      color: WHITE,
      fontSize: '1.375rem',
      height: '27px',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
  containerTitleImg: {
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: '70px',
      maxHeight: '45px',
      '@media screen and (max-width: 600px)': {
        maxWidth: '51px',
        maxHeight: '33px',
      },
    },
  },
  containerDivider: {
    paddingTop: '23px',
    borderBottom: `dashed 1px ${WHITE}`,
  },
});
