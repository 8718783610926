import React, {useState} from 'react';
import InputField from '../Input';
import logo from '../../assets/Imagens/logo.svg';
import './login.styles.css';

function Login() {
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorSenha, setErrorSenha] = useState(false);

  const onClickLogar = () => {
    setErrorLogin(true);
    setErrorSenha(true);
  };

  const onFocus = (e, name) => {
    if (name === 'login') {
      setErrorLogin(false);
    } else {
      setErrorSenha(false);
    }
  };

  return (
    <div style={{backgroundColor: '#14213d', height: '100vh'}}>
      <div className="login-background">
        <form action="" className="login-form">
          <img className="login-logo" src={logo} alt="Logotipo Acordo Seguro"/>

          <div className="form-group">
            <InputField
              name="login"
              label="Código"
              fullWidth
              error={errorLogin}
              errorMessage="Inválido"
              onFocus={e => onFocus(e, 'login')}
            />
          </div>

          <div className="form-group log-status">
            <InputField
              name="senha"
              label="Senha"
              type="password"
              fullWidth
              error={errorSenha}
              errorMessage="Inválido"
              onFocus={e => onFocus(e, 'senha')}
            />
          </div>

          <button type="button" className="log-btn" id="button" onClick={onClickLogar}>Entrar</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
