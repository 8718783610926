import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SHADOW, WARM_GRAY } from '../../../utils/colors/colors.contants';

export default makeStyles({
  divImgButton: {
    padding: '12px 0px',
    // '& img': {
    //   width: 'auto',
    //   height: 'auto',
    // },
  },
  divButtonLabel: {
    marginTop: '30px',
    '& p': {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
      },
    },
  },
  button: {
    padding: '10px 5px',
    borderRadius: '20px',
    width: '100%',
    boxShadow: `0 0 5px 0 ${GRAY_SHADOW}`,
    '&:disabled': {
      color: WARM_GRAY,
    },
  },
});
