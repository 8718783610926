import {
  SELECT_OPCAO_PAGAMENTO,
  SET_DIALOG_PAGE_VIEW,
  SET_OPCOES_PAGAMENTO,
  SET_PAGE_VIEW_ACTIONS,
  SET_DADOS_CARTAO_CREDITO, SET_GLOBAL_VALUES,
} from './negociar.constants';

function buildOpcoesDePagamento() {
  return {
    qtdeParcelas: 1,
    valorParcela: 0,
    valorTotal: 0,
    textoComentario: '',
  };
}

function buildActionsPageView() {
  return {
    loading: false,
    dialog: {
      contentText: '',
      infoContentText: '',
      confirmButtonText: '',
      isSucess: false,
      open: false,
      onCallbackhandler: null,
    },
  };
}
function buildDadosCartaoCredito() {
  return {
    number: '',
    expiry: '',
    name: '',
    cvc: '',
    validExpiry: '',
  };
}
function buildInitialStates() {
  return {
    opcoesPagamento: [buildOpcoesDePagamento()],
    opcaoPagamentoSelected: buildOpcoesDePagamento(),
    dadosCartaoCredito: buildDadosCartaoCredito(),
    actionsPageView: buildActionsPageView(),
  };
}

export const setGlobalValuesAction = (name, value) => ({
  type: SET_GLOBAL_VALUES,
  name,
  value,
});

export const setDialogPageViewAction = dialog => ({
  type: SET_DIALOG_PAGE_VIEW,
  dialog,
});

export const setDadosCartaoCreditoAction = (name, value) => ({
  type: SET_DADOS_CARTAO_CREDITO,
  name,
  value,
});

export const selectOpcaoPagamentoAction = opcaoPagamentoSelected => ({
  type: SELECT_OPCAO_PAGAMENTO,
  opcaoPagamentoSelected,
});

export const setOpcoesPagamentoActions = opcoesPagamento => ({
  type: SET_OPCOES_PAGAMENTO,
  opcoesPagamento,
});

export const setActionsNegociarPageViewAction = (name, value) => ({
  type: SET_PAGE_VIEW_ACTIONS,
  name,
  value,
});


function setOpcoesPagamentoHandler(states, actions) {
  const { opcoesPagamento } = actions;
  return {
    ...states,
    opcoesPagamento: [...opcoesPagamento],
  };
}

function selectOpcaoPagamentoHandler(states, actions) {
  const {
    qtdeParcelas,
    valorParcela,
    valorTotal,
    textoComentario,
  } = actions.opcaoPagamentoSelected;

  return {
    ...states,
    opcaoPagamentoSelected: {
      ...states.opcaoPagamentoSelected,
      qtdeParcelas,
      valorParcela,
      valorTotal,
      textoComentario,
    },
  };
}

function setActionsNegociarPageViewHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      [name]: value,
    },
  };
}

function setDadosCartaoCreditoHandler(states, action) {
  const { name, value } = action;
  return {
    ...states,
    dadosCartaoCredito: {
      ...states.dadosCartaoCredito,
      [name]: value,
    },
  };
}

function setDialogPageViewHandler(states, actions) {
  const {
    contentText,
    infoContentText,
    confirmButtonText,
    isSucess,
    open,
  } = actions.dialog;

  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      dialog: {
        ...states.dialog,
        contentText,
        infoContentText,
        confirmButtonText,
        isSucess,
        open,
      },
    },
  };
}

function setGlobalValuesHandler(states, action) {
  const { name, value } = action;
  return {
    ...states,
    [name]: value,
  };
}

export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_OPCOES_PAGAMENTO:
      return setOpcoesPagamentoHandler(states, actions);
    case SELECT_OPCAO_PAGAMENTO:
      return selectOpcaoPagamentoHandler(states, actions);
    case SET_PAGE_VIEW_ACTIONS:
      return setActionsNegociarPageViewHandler(states, actions);
    case SET_DADOS_CARTAO_CREDITO:
      return setDadosCartaoCreditoHandler(states, actions);
    case SET_DIALOG_PAGE_VIEW:
      return setDialogPageViewHandler(states, actions);
    case SET_GLOBAL_VALUES:
      return setGlobalValuesHandler(states, actions);
    default:
      return states;
  }
};
