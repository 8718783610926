import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  BLACK_TWO, NAVY_BLUE, ORANGE_PRIMARY, WARM_GRAY, WHITE, WHITE_ELEMENTARY,
} from '../../../utils/colors/colors.contants';

export default makeStyles({
  primary: {
    width: '19.5rem',
    height: '3.625rem',
    color: BLACK_TWO,
    borderRadius: '29px',
    fontSize: '1rem',
    backgroundColor: ORANGE_PRIMARY,
    borderColor: ORANGE_PRIMARY,
    '&.MuiButton-root': {
      textTransform: 'inherit',
    },
    '&:hover': {
      backgroundColor: ORANGE_PRIMARY,
    },
  },
  secondary: {
    width: '19.5rem',
    height: '3.625rem',
    color: NAVY_BLUE,
    borderRadius: '29px',
    borderColor: NAVY_BLUE,
    fontSize: '1rem',
    backgroundColor: WHITE,
    '&:disabled': {
      backgroundColor: WHITE_ELEMENTARY,
      color: WARM_GRAY,
    },
    '&:hover': {
      backgroundColor: WHITE,
    },
    '@media screen and (max-width: 600px)': {
      width: '100%',
      height: '2.625rem',
    },
  },
  dialog: {
    width: '17.25rem',
    height: '3.625rem',
    color: WHITE,
    borderRadius: '29px',
    borderColor: NAVY_BLUE,
    fontSize: '1rem',
    backgroundColor: NAVY_BLUE,
    '&:hover': {
      backgroundColor: NAVY_BLUE,
    },
    '@media screen and (max-width: 600px)': {
      width: '100%',
      height: '2.625rem',
    },
  },
  div__arrowBtn: {
    width: 'fit-content',
    maxHeight: '34px',
    cursor: 'pointer',
    '& i': {
      fontSize: '32px',
      '@media screen and (max-width: 600px)': {
        fontSize: '24px',
      },
    },
    '& span': {
      fontSize: '16px',
      '&.MuiTouchRipple-root': {
        display: 'none',
      },
    },

  },
  arrow: {
    '&.MuiButton-root': {
      transition: 'none',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.MuiButton-outlined': {
      border: 'none',
      padding: '0 0 13px 0',
      '@media screen and (max-width: 600px)': {
        padding: '0 0 6px 0',
      },
    },
    '&.MuiButton-labe': {
      fontSize: '16px',
    },
  },
  div__icon: {
    display: 'flex',
    position: 'relative',
    right: '20px',
  },
});
