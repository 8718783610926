import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { InputLabel } from '@material-ui/core';
import TextareaAutosize from 'react-textarea-autosize';

import TooltipUI from '../../Tooltip/Tooltip';
import useStyles from './TextareaField.styles';

function TextareaField({
  value,
  disabled = false,
  error = false,
  errorMessage = '',
  label,
  loading,
  fullWidth = true,
  labelProps,
  formProps,
  classForm = '',
  maxCounter = 1000,
  showCounter = false,
  showToolTip = true,
  onChange = () => null,
  tooltipProps = {},
  ...props

}) {
  const styles = useStyles({ disabled });
  const [counterLabel, setCounterLabel] = useState(`${value.length}/${maxCounter}`);

  // Reseta o contador.
  useEffect(() => {
    if (value === '') {
      setCounterLabel(`0/${maxCounter}`);
    }
  }, [setCounterLabel, value, maxCounter]);

  const changeHandler = useCallback((event) => {
    setCounterLabel(`${event.target.value.length}/${maxCounter}`);
    onChange(event);
  }, [setCounterLabel, onChange, maxCounter]);

  const clazz = clsx(
    styles.formRoot,
    classForm,
    {
      [styles.loading]: loading,
      [styles.fullWidth]: fullWidth,
      [styles.error]: error,
    },
  );

  const buildContent = () => (
    <div
      {...formProps}
      className={clazz}
    >
      <InputLabel error={error} {...labelProps}>{label}</InputLabel>
      <TextareaAutosize
        disabled={disabled}
        value={value}
        onChange={changeHandler}
        {...props}
      />
    </div>
  );

  const buildField = () => {
    const content = buildContent();
    if (error && showToolTip) {
      return (
        <TooltipUI error title={errorMessage} placement="bottom-start" {...tooltipProps}>
          {content}
        </TooltipUI>
      );
    }
    if (error && !showToolTip) {
      return (
        <div className={clsx(styles.formRoot, { [styles.loading]: loading, [styles.fullWidth]: fullWidth })}>
          {content}
          <div style={{ color: '#ff000f', fontSize: '14px' }}>{errorMessage}</div>
        </div>
      );
    }
    return content;
  };

  return (
    <>
      {buildField()}
      {showCounter && (
        <div className={styles.counter}>
          <span>{counterLabel}</span>
        </div>
      )}
    </>
  );
}

export default React.memo(TextareaField);
