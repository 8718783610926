import { gateway } from '../api.service';
import { makeQueryParams } from '../../utils/utils';

const ACORDO_SEGURO_SERVICE = process.env.REACT_APP_ACORDO_SEGURO_URI;

export function getPossiveisAcrodo(idProcesso, operacao) {
  const queryParams = makeQueryParams({ operacao });
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/acordos/${idProcesso}?${queryParams}`);
}

export function getBoletoAcordo(idRecebimento) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/acordos/${idRecebimento}/boletos`, {
    responseType: 'blob',
  });
}

export function getPixAcordo(idRecebimento) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/acordos/${idRecebimento}/pix`, {
    responseType: 'blob',
  });
}

export function realizarAcordoDividaBoleto(idProcesso, acordoSelected) {
  return gateway.post(`${ACORDO_SEGURO_SERVICE}/acordos/${idProcesso}/boleto`, acordoSelected);
}

export function realizarAcordoDividaPix(idProcesso, acordoSelected) {
  return gateway.post(`${ACORDO_SEGURO_SERVICE}/acordos/${idProcesso}/pix`, acordoSelected);
}

export function realizarAcordoDividaCartaoCredito(idProcesso, payload) {
  return gateway.post(`${ACORDO_SEGURO_SERVICE}/acordos/${idProcesso}/cartao-credito`, payload);
}

export function getReciboCartaoCredito(idProcesso) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/acordos/${idProcesso}/cartao-credito/recibos`);
}
