import React from 'react';
import useStyles from './headerContainer.styles';

function HeaderContainer({ children }) {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
}

export default HeaderContainer;
