import React from 'react';
import Zoom from '@material-ui/core/Zoom';

function ZoomUI({ children, loading }) {
  return (
    <Zoom in={!loading} style={{ transitionDelay: (!loading) ? '500ms' : '0ms' }}>
      {children}
    </Zoom>
  );
}

export default ZoomUI;
