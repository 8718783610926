import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import useStyle from './listButton.styles';
import FadeUI from '../Transitions/FadeUI';
import { formatMoney } from '../../../utils/utils';

function ListButon({ options, onSelectOption, qtdeParcelasDefault = 1 }) {
  const styles = useStyle();
  const listRef = useRef();

  const [qtdeParcelaSelecionada, setqtdeParcelaSelecionada] = useState(qtdeParcelasDefault);

  const onChangeQtdeParcelaSelecionada = useCallback((option) => {
    setqtdeParcelaSelecionada(option.qtdeParcelas);
    onSelectOption(option);
  }, [onSelectOption]);

  const buildListButtonOption = () => (
    options.map(option => (
      <Grid item lg={12} md={12} sm={12} xs={12} key={option.qtdeParcelas} ref={listRef}>
        <FadeUI timeoutEffect={1000}>
          <Button
            className={clsx({ [styles.button]: true, [styles.buttonSelected]: (qtdeParcelaSelecionada === option.qtdeParcelas) })}
            onClick={() => onChangeQtdeParcelaSelecionada(option)}
          >
            <Grid container item className={styles.divButton} justify="space-between" alignItems="center">
              <Grid container item lg={3} md={5} sm={5} xs={5} justify="flex-start" className={styles.divButtonFirstLabel}>
                <Typography component="p">
                  {`${option.qtdeParcelas}x ${formatMoney(option.valorParcela)}`}
                </Typography>
              </Grid>
              <Grid item lg={9} md={7} sm={7} xs={7} className={styles.divButtonLabels}>
                <Grid container item justify="flex-end" alignItems="center" className={styles.divButtonSecondLabel}>
                  <Typography component="p">
                    {`Total: ${formatMoney(option.valorTotal)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container item className={styles.divButtonthirdLabel} alignItems="center" justify="flex-end">
                  <Typography component="p">
                    {option.textoComentario}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Button>
        </FadeUI>
      </Grid>
    ))
  );

  return (
    <Grid container spacing={2}>
      {buildListButtonOption()}
    </Grid>

  );
}

export default memo(ListButon);
