import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import useStyle from './cardHeader.styles';

function CardHeader({ img }) {
  const styles = useStyle();
  return (
    <Card className={styles.cardContainer}>
      <Grid container spacing={4} alignItems="center" justify="flex-start">
        <Grid item className={styles.containerTitleImg}>
          <img width={img.width} height={img.height} src={img.srcImg} alt={img.alt} />
        </Grid>
        <Grid item className={styles.containerTitleTextLabel}>
          <Typography component="p">
            {img.title}
          </Typography>
        </Grid>
      </Grid>
      <div className={styles.containerDivider} />
    </Card>
  );
}

export default CardHeader;
