import React, {useCallback, useEffect, useState} from 'react';

import Typography from '@material-ui/core/Typography';

import {useHistory, withRouter} from 'react-router';
import useStyles from './acessoNegado.styles';
import logoAcordeSeguroFooter from '../../../assets/Logos/logo-acorde-seguro-footer.png';
import logoAbeFooter from '../../../assets/Logos/logo-abe-footer.png';
import {NAVY_BLUE} from '../../../utils/colors/colors.contants';


function AcessoNegado({
                        icon, message, isAuth = false, prazoExpirado = false,
                      }) {
  const styles = useStyles();
  const text = message.split('.');
  const history = useHistory();

  const [messageDisplay, setMessageDisplay] = useState('');
  const [isPoolConnectionError, setIsPoolConnectionError] = useState(false);

  // Configura a mensagem.
  // Solução paliativa até corrigir o problema de liberar os pools no banco.
  const setMessageDisplayHandler = useCallback((textSplit) => {
    if (textSplit[0].startsWith('Unable')) {
      setIsPoolConnectionError(true);
      setMessageDisplay('Não foi possível estabelecer uma conexão');
    } else {
      setIsPoolConnectionError(false);
      if (textSplit[0] !== '') {
        setMessageDisplay(`${textSplit[0]}.`);
      } else {
        setMessageDisplay('Informações não encontradas.');
      }
    }
  }, [setMessageDisplay, setIsPoolConnectionError]);

  // Redireciona o cara para a página da ABE caso ele nao esteja com o token na sessao e o prazo dele foi expirado.
  // isAuth -> tem token gravado na session storage.
  // prazoExpirado -> se o prazo do acesso nao expirou.
  useEffect(() => {
    if (!isAuth && !prazoExpirado) {
      history.replace('/');
    }
  }, [isAuth, prazoExpirado, history]);

  // Configura a mensagem que será exibida abaixo do Ops!
  useEffect(() => {
    setMessageDisplayHandler(text);
  }, [setMessageDisplayHandler, text]);

  return (!isAuth && !prazoExpirado ? null : (
    <div className={styles.divRoot}>
      <div className={styles.divHeaderContainer}>
        <img src={logoAcordeSeguroFooter} alt="Acordo Seguro" className={styles.acordoSeguroImage}/>
        <div className={styles.verticalLine}/>
        <img src={logoAbeFooter} alt="Abe" className={styles.abeImage}/>
      </div>
      <div className={styles.divRootContainer}>
        <div className={styles.contentDivContainer}>
          <img src={icon} alt="Não Encontrado" className={styles.naoEncontradoImage}/>

          <Typography component="h1" className={styles.typograhpyFirstText}>Ops!</Typography>
          <Typography component="h2" className={styles.typograhpySecondText}>{messageDisplay}</Typography>
          {(!isPoolConnectionError && (text.length > 1 && text[1] !== '')) && (
            <Typography component="h2" className={styles.typograhpySecondText}>{`${text[1]}.`}</Typography>
          )}
          <h4 className={styles.contatosText}>CONTATOS</h4>
          <div className={styles.divContainerTelefones}>
            <div className={styles.divIconTelefoneETexto}>
              <div className={styles.divIconTelefone}>
                <i className="fas fa-phone-alt" color={NAVY_BLUE}/>
              </div>
              <h4 className={styles.infoContatosTitle}>Telefones:</h4>
            </div>
            <div className={styles.divTelefoneEcusto}>
              <h4 className={styles.infoContatosMarginText}>0300 11 11 223 (custo de ligação local)</h4>
              <h6 className={styles.indicatorCustoLocalMobile}>*</h6>
            </div>
            <h4 className={styles.infoContatosSeparator}>/</h4>
            <h4 className={styles.infoContatosMarginText}>(11) 3156-4877</h4>
          </div>
          <div className={styles.divContainerContatos}>
            <div className={styles.divIconWhatsApp}>
              <i className="fab fa-whatsapp"/>
            </div>
            <h4 className={styles.infoContatosTitle}>WhatsApp:</h4>
            <h4 className={styles.infoContatosMarginText}>(11) 99958-5002</h4>
          </div>
          <div className={styles.divContainerContatos}>
            <div className={styles.divIcons}>
              <i className="fas fa-envelope"/>
            </div>
            <h4 className={styles.infoContatosTitle}>E-mail:</h4>
            <h4 className={styles.infoContatosMarginText}>advocacia@grejoadvogados.com.br</h4>
          </div>
        </div>
      </div>
    </div>
  ));
}

export default withRouter(AcessoNegado);
