import { gateway } from '../api.service';
import { makeQueryParams } from '../../utils/utils';

const ACORDO_SEGURO_SERVICE = process.env.REACT_APP_ACORDO_SEGURO_URI;

export function getTitulosProcesso(numeroControleAcordoSeguro) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/processos/${numeroControleAcordoSeguro}/titulos`);
}

export function getDevedor(idDevedor) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/devedores/${idDevedor}`);
}

export function getDadosCredor(idCliente) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/clientes/${idCliente}`);
}

export function getParametrosGerais(numeroControleAcordoSeguro) {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/parametros/${numeroControleAcordoSeguro}`);
}

export function getOutrasDividas(idProcesso) {
  const queryParams = makeQueryParams({ idProcesso });
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/devedores/dividas?${queryParams}`);
}

export function getLogoCliente(idCliente, format = 'BASE_64') {
  return gateway.get(`${ACORDO_SEGURO_SERVICE}/clientes/${idCliente}/logo`, { params: { format } });
}

export function enviarEmail(emailContent) {
  return gateway.post(`${ACORDO_SEGURO_SERVICE}/email`, emailContent);
}

export function enviarComprovanteEmail(emailContent) {
  return gateway.post(`${ACORDO_SEGURO_SERVICE}/email/comprovante`, emailContent);
}
