import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK_TWO, PURE_GRAY } from '../../utils/colors/colors.contants';


export default makeStyles({

  div__root: {
    minHeight: '70px',
    maxHeight: '100px',
    backgroundColor: PURE_GRAY,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '@media screen and (max-width: 600px)': {
      display: 'block',
    },
  },
  div__versao: {
    width: '50%',
    display: 'flex',
    marginLeft: '46px',
    fontSize: '12px',
    color: BLACK_TWO,
    '@media screen and (max-width: 600px)': {
      width: '100%',
      marginLeft: 0,
      justifyContent: 'center',
      '& span': {
        marginTop: '18px',
        marginBottom: '7px',
      },
    },
  },

  div__techno: {
    width:'50%',
    fontSize:'12px',
    display:'flex',
    marginRight:'17px',
    justifyContent:'flex-end',
    '@media screen and (max-width: 600px)': {
      width:'100%',
      justifyContent: 'center',
      marginBottom:'16px',
      
    }

    
  },
  

  
});
