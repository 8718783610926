import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useStyles from './cardButtonUI.styles';
import FadeUI from '../Transitions/FadeUI';

function CardButtonUI({
  disabled, imgCardButton, onClick, containerProps, gridImgProps,
}) {
  const styles = useStyles();
  return (
    <FadeUI timeoutEffect={1000}>
      <Grid container {...containerProps}>
        <Button disabled={disabled} onClick={onClick} variant={disabled ? 'contained' : 'outlined'} className={styles.button}>
          <Grid container className={styles.divImgButton}>
            <Grid container item justify="center" alignItems="center" lg={12} {...gridImgProps}>
              {disabled
                ? (
                  <img
                    width={imgCardButton.width}
                    height={imgCardButton.height}
                    src={imgCardButton.srcImgDisabled}
                    alt={imgCardButton.alt}
                  />
                )
                : (
                  <img
                    width={imgCardButton.width}
                    height={imgCardButton.height}
                    src={imgCardButton.srcImg}
                    alt={imgCardButton.alt}
                  />
                )}
            </Grid>
            <Grid container item lg={12} justify="center" alignItems="center" className={styles.divButtonLabel}>
              <Typography component="p">
                {imgCardButton.title}
              </Typography>
            </Grid>
          </Grid>
        </Button>
      </Grid>
    </FadeUI>
  );
}

export default memo(CardButtonUI);
