import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import PaperUI from '../../UI/Paper/PaperUI';
import { FALE_CONOSCO_SUB_TITLE, FALE_CONOSCO_SUB_TITLE2, FALE_CONOSCO_TITLE } from './faleConosco.constants';
import useStyles from './faleConosco.styles';
import InputField from '../../Input';
import TextareaField from '../../UI/Textarea/TextareaField';
import RadioField from '../../UI/Radio/RadioField';
import { GroupDefinitions } from '../../UI/Radio/radioGroup.styles';
import { setFaleConoscoAction } from '../../../store/home/home.store';
import ButtonUI from '../../UI/Button/ButtonUI';
import { faleConoscoSchema, motivosEnum } from './faleConosco.utils';
import { enviarEmailFaleConoscoAction } from '../../../store/home/home.saga';

function FaleConosco({ errosFaleConosco, setErrosFaleConosco }) {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [hasErrors, setHasErrors] = useState(false);
  const faleConosco = useSelector(states => ({ ...states.homeStore.faleConosco }));

  const messageStyle = clsx({
    [styles.div__messageContainer]: true,
    [styles.div__messageContainer_success]: !faleConosco.errorEnvioEmail,
    [styles.div__messageContainer_error]: faleConosco.errorEnvioEmail,
  });

  const onChangeMotivoHandler = useCallback((event) => {
    const { value } = event.target;
    dispatch(setFaleConoscoAction('motivo', value));
  }, [dispatch]);

  const onChangeHandler = useCallback((event) => {
    const { name, value } = event.target;
    dispatch(setFaleConoscoAction(name, value));
  }, [dispatch]);

  // Valida o form com o Yup.
  // Se nao tiver erros, chama o saga para enviar o e-mail.
  // Caso tenha erros, vai alertar no campo.
  const onEnviarHandler = useCallback(async () => {
    setHasErrors(false);
    const schemaToValidate = {
      nome: faleConosco.nome,
      email: faleConosco.email,
      mensagem: faleConosco.mensagem,
    };
    try {
      await faleConoscoSchema.validate(schemaToValidate, { abortEarly: false });
      if (!hasErrors) {
        dispatch(enviarEmailFaleConoscoAction());
      }
    } catch (e) {
      setHasErrors(true);
      e.inner.forEach((err) => {
        errosFaleConosco[err.path].error = true;
        errosFaleConosco[err.path].message = err.message;
      });
      setErrosFaleConosco({ ...errosFaleConosco });
    }
  }, [dispatch, errosFaleConosco, faleConosco, setErrosFaleConosco, hasErrors]);

  // Limpa os erros dos campos.
  const onFocusHandler = useCallback((event) => {
    const { name } = event.target;
    setErrosFaleConosco({
      ...errosFaleConosco,
      [name]: {
        error: false,
      },
    });
  }, [errosFaleConosco, setErrosFaleConosco]);

  return (
    <PaperUI>
      <Grid container item alignItems="center" justify="center" lg={12} md={12} sm={12} className={styles.title__container}>
        <Typography component="p">
          {FALE_CONOSCO_TITLE}
        </Typography>
      </Grid>
      <Grid container item alignItems="center" justify="center" lg={12} md={12} sm={12}>
        <Typography component="p" className={styles.typography__subTitle}>
          {FALE_CONOSCO_SUB_TITLE}
        </Typography>
      </Grid>
      <Grid container item alignItems="center" justify="center" lg={12}>
        <Typography component="p" className={styles.typography__subTitle}>
          {FALE_CONOSCO_SUB_TITLE2}
        </Typography>
      </Grid>
      <Grid container item alignItems="center" justify="center" lg={12}>
        {faleConosco.messageAposEnvio && (
          <div className={messageStyle}>
            <i className={faleConosco.iconMessageAposEnvio} />
            <Typography component="p">{faleConosco.messageAposEnvio}</Typography>
          </div>
        )}
      </Grid>
      <Grid container item alignItems="flex-start" justify="flex-start" lg={12} className={styles.grid__inputFields}>
        <InputField
          showToolTip={false}
          isForm
          fullWidth
          label="Nome*"
          name="nome"
          error={errosFaleConosco.nome.error}
          errorMessage={errosFaleConosco.nome.message}
          value={faleConosco.nome}
          onFocus={onFocusHandler}
          onChange={onChangeHandler}
        />
      </Grid>
      <Grid container item alignItems="flex-start" justify="flex-start" lg={12} className={styles.grid__inputFields}>
        <InputField
          showToolTip={false}
          isForm
          fullWidth
          label="Email*"
          name="email"
          error={errosFaleConosco.email.error}
          errorMessage={errosFaleConosco.email.message}
          value={faleConosco.email}
          onFocus={onFocusHandler}
          onChange={onChangeHandler}
        />
      </Grid>
      <Grid container item alignItems="flex-start" justify="flex-start" lg={12} className={styles.grid__inputFields}>
        <TextareaField
          showToolTip={false}
          showCounter
          maxCounter={faleConosco.qtdeMaxAssuntoFaleConosco}
          label="Mensagem*"
          classForm={styles.areaSize}
          maxLength={faleConosco.qtdeMaxAssuntoFaleConosco}
          name="mensagem"
          error={errosFaleConosco.mensagem.error}
          errorMessage={errosFaleConosco.mensagem.message}
          value={faleConosco.mensagem}
          onFocus={onFocusHandler}
          onChange={onChangeHandler}
        />
      </Grid>
      <Grid container item alignItems="flex-start" justify="flex-start" lg={12} className={styles.grid__inputFields}>
        <Typography component="h3" className={styles.typography__subTitle}>
          Motivo:
        </Typography>
        <GroupDefinitions
          aria-label="fale-conosco-definicoes"
          value={faleConosco.motivo}
          onChange={onChangeMotivoHandler}
        >
          <Grid item container lg={3}>
            <RadioField label="Solicitação" value={motivosEnum.solicitacao} />
          </Grid>
          <Grid item container lg={3}>
            <RadioField label="Sugestão" value={motivosEnum.sugestao} />
          </Grid>
          <Grid item container lg={3}>
            <RadioField label="Reclamação" value={motivosEnum.reclamacao} />
          </Grid>
          <Grid item container lg={3}>
            <RadioField label="Elogio" value={motivosEnum.elogio} />
          </Grid>
        </GroupDefinitions>
      </Grid>
      <Grid container item alignItems="center" justify="center" lg={12} className={styles.grid__button}>
        <ButtonUI variant="dialog" label="Enviar" onClick={onEnviarHandler} />
      </Grid>
    </PaperUI>
  );
}

export default FaleConosco;
