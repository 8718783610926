import {
  CLEAR_STATE_FALE_CONOSCO,
  SET_CAPA,
  SET_DADOS_CREDOR, SET_DADOS_ENVIAR_COMPROVANTE,
  SET_DADOS_RECIBO,
  SET_DEVEDOR, RESET_DADOS_ENVIAR_COMPROVANTE,
  SET_FALE_CONOSCO,
  SET_GLOBALS,
  SET_OUTRAS_DIVIDAS,
  SET_PAGE_VIEW_ACTIONS,
  SET_PARAMETRO_GERAIS,
  SET_TITULOS,
} from './home.constants';
import capa01 from '../../assets/Capas/new-capa1.png';
import capa02 from '../../assets/Capas/new-capa2.png';
import capa03 from '../../assets/Capas/new-capa3.png';
import capa04 from '../../assets/Capas/new-capa4.png';
import { buildDadosReciboObject } from '../../components/UI/Recibo/comprovantePagamentoCartao.utils';


function buildDevedor() {
  return {
    nome: '',
    nomeFantasia: '',
    cnpjCpf: '',
    endereco: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
    ddd: '',
    telefone: '',
    email: '',
  };
}

function buildDadosCredor() {
  return {
    nome: '',
    nomeFantasia: '',
    cnpjCpf: '',
    endereco: '',
    bairro: '',
    cidade: '',
    estado: '',
    cep: '',
  };
}

function buildActionsPageView() {
  return {
    loading: false,
    loadingHome: false,
    loadingLogoCliente: false,
    utilizarBoleto: false,
    utilizarCartao: false,
    utilizarPix: false,
  };
}

function buildSearchCriteria() {
  return {
    idDevedor: '',
    idCliente: '',
    idProcesso: '',
    tipoAcordoRealizado: '',
  };
}

function buildResumoDividas() {
  return {
    valorTotalProcesso: 0,
    valorTotalOutros: 0,
    valorPrincipal: 0,
    valorHonorarios: 0,
    valorJuros: 0,
  };
}

function buildCanaisAtendimento() {
  return {
    emailContato: '',
    telefoneContato1: '',
    telefoneContato2: '',
    celularContato1: '',
  };
}

function buildFaleConosco() {
  return {
    nome: '',
    email: '',
    mensagem: '',
    motivo: 'SOLICITACAO',
    qtdeMaxAssuntoFaleConosco: '',
    emailFaleConosco: '',
    errorEnvioEmail: false,
    messageAposEnvio: '',
    iconMessageAposEnvio: '',
  };
}

function buildDadosEnviarComprovante() {
  return {
    ddd: '',
    telefone: '',
    emailDestinatarioDevedor: '',
    dialog: {
      contentText: '',
      infoContentText: '',
      confirmButtonText: '',
      isSucess: false,
      open: false,
      onCallbackhandler: null,
    },
  };
}

function buildInitialStates() {
  return {
    capaHome: null,
    logoCliente: { imagePreviewUrl: null },
    actionsPageView: buildActionsPageView(),
    outrasDividas: [],
    titulos: [],
    devedor: buildDevedor(),
    dadosCredor: buildDadosCredor(),
    searchCriteria: buildSearchCriteria(),
    resumoDividas: buildResumoDividas(),
    canaisAtendimento: buildCanaisAtendimento(),
    faleConosco: buildFaleConosco(),
    dadosRecibo: [],
    dadosEnviarCompravante: buildDadosEnviarComprovante(),
  };
}

export const clearStatesFaleConoscoAction = () => ({
  type: CLEAR_STATE_FALE_CONOSCO,
});

export const setFaleConoscoAction = (name, value) => ({
  type: SET_FALE_CONOSCO,
  name,
  value,
});

export const setDadosReciboAction = dadosRecibo => ({
  type: SET_DADOS_RECIBO,
  dadosRecibo,
});

export const setOutrasDividasAction = outrasDividas => ({
  type: SET_OUTRAS_DIVIDAS,
  outrasDividas,
});

export const setCapaHomeAction = value => ({
  type: SET_CAPA,
  value,
});

export const setGlobalsActions = (name, value) => ({
  type: SET_GLOBALS,
  name,
  value,
});

export const setActionsPageViewAction = (name, value) => ({
  type: SET_PAGE_VIEW_ACTIONS,
  name,
  value,
});

export const setTitulosAction = titulos => ({
  type: SET_TITULOS,
  titulos,
});

export const setDevedorAction = data => ({
  type: SET_DEVEDOR,
  data,
});

export const setDadosCredorAction = data => ({
  type: SET_DADOS_CREDOR,
  data,
});

export const setParametrosGeraisActions = parametrosGerais => ({
  type: SET_PARAMETRO_GERAIS,
  parametrosGerais,
});

export const setDadosEnviarComprovanteAction = (name, value) => ({
  type: SET_DADOS_ENVIAR_COMPROVANTE,
  name,
  value,
});

export const resetDadosEnviarComprovanteAction = () => ({
  type: RESET_DADOS_ENVIAR_COMPROVANTE,
});

function setDialogEnviarComprovanteHandler(states) {
  return {
    ...states,
    dadosEnviarCompravante: buildDadosEnviarComprovante(),
  };
}

function setDadosEnviarComprovanteHandler(states, actions) {
  const { name, value } = actions;

  return {
    ...states,
    dadosEnviarCompravante: {
      ...states.dadosEnviarCompravante,
      [name]: value,
    },
  };
}

function setTitulosHandler(states, actions) {
  const { titulos } = actions;
  return {
    ...states,
    titulos: [...titulos],
  };
}

function setOutrasDividasHandler(states, actions) {
  const { outrasDividas } = actions;
  return {
    ...states,
    outrasDividas: [...outrasDividas],
  };
}

function setCapaHandler(states, actions) {
  const { value } = actions;
  let newCapaHome = null;

  if (value <= 2) {
    newCapaHome = capa01;
  } else if (value > 2 && value <= 4) {
    newCapaHome = capa02;
  } else if (value > 4 && value <= 7) {
    newCapaHome = capa03;
  } else {
    newCapaHome = capa04;
  }
  return {
    ...states,
    capaHome: newCapaHome,
  };
}

function setActionsPageViewHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      [name]: value,
    },
  };
}

function setGlobalHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setDevedorHandler(states, actions) {
  const {
    nome,
    nomeFantasia,
    cnpjCpf,
    endereco,
    bairro,
    cidade,
    estado,
    cep,
    ddd,
    telefone,
    email,
  } = actions.data;
  return {
    ...states,
    devedor: {
      ...states.devedor,
      nome,
      nomeFantasia,
      cnpjCpf,
      endereco,
      bairro,
      cidade,
      estado,
      cep,
      ddd,
      telefone,
      email,
    },
  };
}

function setParametrosGeraisHandler(states, actions) {
  const { parametrosGerais } = actions;
  return {
    ...states,
    actionsPageView: {
      ...states.actionsPageView,
      utilizarBoleto: parametrosGerais.utilizarBoleto,
      utilizarCartao: parametrosGerais.utilizarCartao,
      utilizarPix: parametrosGerais.utilizarPix,
    },
    searchCriteria: {
      ...states.searchCriteria,
      idCliente: parametrosGerais.idCliente,
      idDevedor: parametrosGerais.idDevedor,
      idProcesso: parametrosGerais.idProcesso,
      idRecebimento: parametrosGerais.idRecebimento,
      tipoAcordoRealizado: parametrosGerais.tipoAcordoRealizado,
    },
    resumoDividas: {
      ...states.resumoDividas,
      valorPrincipal: parametrosGerais.valorPrincipal,
      valorHonorarios: parametrosGerais.valorHonorarios,
      valorJuros: parametrosGerais.valorJuros,
      valorTotalProcesso: parametrosGerais.valorTotalProcesso,
      valorTotalOutros: parametrosGerais.valorTotalOutros,
    },
    canaisAtendimento: {
      ...states.canaisAtendimento,
      emailContato: parametrosGerais.emailContato,
      telefoneContato1: parametrosGerais.telefoneContato1,
      telefoneContato2: parametrosGerais.telefoneContato2,
      celularContato1: parametrosGerais.celularContato1,
    },
    faleConosco: {
      ...states.faleConosco,
      qtdeMaxAssuntoFaleConosco: parametrosGerais.qtdeMaxAssuntoFaleConosco,
      emailFaleConosco: parametrosGerais.emailFaleConosco,
    },
  };
}

function setDadosCredorHandler(states, actions) {
  const {
    nome,
    nomeFantasia,
    cnpjCpf,
    endereco,
    bairro,
    cidade,
    estado,
    cep,
  } = actions.data;
  return {
    ...states,
    dadosCredor: {
      ...states.dadosCredor,
      nome,
      nomeFantasia,
      cnpjCpf,
      endereco,
      bairro,
      cidade,
      estado,
      cep,
    },
  };
}

function setDadosReciboHandler(states, action) {
  const { dadosRecibo } = action;
  const newDadosRecibo = buildDadosReciboObject(dadosRecibo);
  const values = Object.keys(newDadosRecibo).map(key => newDadosRecibo[key]);
  return {
    ...states,
    dadosRecibo: values,
  };
}


function setMotivoFaleConoscoHandler(states, action) {
  const { name, value } = action;
  return {
    ...states,
    faleConosco: {
      ...states.faleConosco,
      [name]: value,
    },
  };
}

function clearStatesFaleConoscoHandler(states) {
  return {
    ...states,
    faleConosco: {
      ...states.faleConosco,
      nome: '',
      email: '',
      mensagem: '',
      motivo: 'SOLICITACAO',
    },
  };
}


export default (states = buildInitialStates(), actions) => {
  switch (actions.type) {
    case SET_GLOBALS:
      return setGlobalHandler(states, actions);
    case SET_TITULOS:
      return setTitulosHandler(states, actions);
    case SET_OUTRAS_DIVIDAS:
      return setOutrasDividasHandler(states, actions);
    case SET_DEVEDOR:
      return setDevedorHandler(states, actions);
    case SET_PAGE_VIEW_ACTIONS:
      return setActionsPageViewHandler(states, actions);
    case SET_PARAMETRO_GERAIS:
      return setParametrosGeraisHandler(states, actions);
    case SET_DADOS_CREDOR:
      return setDadosCredorHandler(states, actions);
    case SET_CAPA:
      return setCapaHandler(states, actions);
    case SET_DADOS_RECIBO:
      return setDadosReciboHandler(states, actions);
    case SET_FALE_CONOSCO:
      return setMotivoFaleConoscoHandler(states, actions);
    case CLEAR_STATE_FALE_CONOSCO:
      return clearStatesFaleConoscoHandler(states);
    case SET_DADOS_ENVIAR_COMPROVANTE:
      return setDadosEnviarComprovanteHandler(states, actions);
    case RESET_DADOS_ENVIAR_COMPROVANTE:
      return setDialogEnviarComprovanteHandler(states);
    default:
      return states;
  }
};
