import makeStyles from '@material-ui/core/styles/makeStyles';
import { PURE_GRAY } from '../../../utils/colors/colors.contants';

export default makeStyles({
  button: {
    width: '100%',
    borderRadius: '25px',
    boxShadow: `0 0 5px 0 ${PURE_GRAY}`,
    textTransform: 'none',
  },
  buttonSelected: {
    backgroundColor: PURE_GRAY,
    '&:hover': {
      backgroundColor: PURE_GRAY,
    },
  },
  divButton: {
    margin: '16px',
    '@media screen and (max-width: 600px)': {
      margin: '16px 5px',
    },
  },
  divButtonLabels: {
    '@media screen and (max-width: 433px)': {
      justifyContent: 'center',
    },
  },
  divButtonFirstLabel: {
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
        fontWeight: 'bold',
      },
    },
  },
  divButtonSecondLabel: {
    '& p': {
      textAlign: 'right',
      fontSize: '1rem',
      fontWeight: 'normal',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
        fontWeight: 'normal',
      },
    },
  },
  divButtonthirdLabel: {
    paddingTop: '6px',
    '& p': {
      textAlign: 'left',
      fontSize: '1rem',
      fontWeight: 'bold',
      '@media screen and (max-width: 600px)': {
        fontSize: '0.75rem',
        fontWeight: 'bold',
      },
    },
  },
});
