import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  gridBoxShadow: {
    marginTop: '1.03125rem',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    backgroundColor: WHITE,
    paddingBottom: '3.375rem',
  },
  gridLogoEmpresa: {
    paddingTop: '32px',
    paddingBottom: '32px',
  },
  imgLogoCliente: {
    maxWidth: '360px',
    maxHeight: '140px',
  },
  typograpyTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingBottom: '3.7%',
  },
  typograpyInfo: {
    fontSize: '0.875rem',
    '@media screen and (max-width: 600px)': {
      fontSize: '0.75rem',
    },
  },
});
