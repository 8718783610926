import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(states => states.authenticateStore.isAuthenticated);

  const rendered = props => ((isAuthenticated)
    ? <Component {...props} />
    : <Redirect to={{ pathname: '/acesso-negado', state: { from: props.location } }} />
  );

  return <Route {...rest} render={rendered} />;
}

export default PrivateRoute;
