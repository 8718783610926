import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Home from '../components/Home/Home';
import Authentication from '../hoc/Authentication';
import AcessoNegado from '../components/UI/AcessoNegado/AcessoNegado';
import naoEncontradoImage from '../assets/Icons/nao-encontrado.png';
import relogioPrazoExpirado from '../assets/Icons/relogio-prazo-expirado.png';
import PrivateRoute from '../hoc/PrivateRoute';
import Negociar from '../components/Negociar/Negociar';
import NegociarProvider from '../store/negociar/NegociarProvider';
import LandPage from '../components/LandPage/LandPage';
import Login from '../components/Login/Login';

function RoutesSettings() {
  const errorMessage = useSelector(states => states.authenticateStore.errorMessage);
  const isInfoNaoEncontrada = useSelector(states => states.authenticateStore.isInfoNaoEncontrada);
  const isAuthenticated = useSelector(states => states.authenticateStore.isAuthenticated);
  const isPrazoExpirado = useSelector(states => states.authenticateStore.isPrazoExpirado);

  const iconSrc = isInfoNaoEncontrada ? naoEncontradoImage : relogioPrazoExpirado;

  const naoEncontradoPage = () => (
    <AcessoNegado
      icon={iconSrc}
      prazoExpirado={isPrazoExpirado}
      isAuth={isAuthenticated}
      message="Informações não encontradas."
    />
  );
  const prazoExpiradoPage = () => (
    <AcessoNegado icon={iconSrc} prazoExpirado={isPrazoExpirado} isAuth={isAuthenticated} message={errorMessage}/>);

  const negociarComponent = () => (
    <NegociarProvider>
      <Negociar/>
    </NegociarProvider>
  );


  const landPageComponent = () => (
    <>
      <LandPage/>
    </>
  );

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/dashboard" exact component={Home}/>
        <PrivateRoute path="/negociar" exact component={negociarComponent}/>
        <Route path="/" exact component={landPageComponent}/>
        <Route path="/autenticar" exact component={Login}/>
        <Route path="/auth/:credentials" exact component={Authentication}/>
        <Route path="/auth/:username/:password" exact component={Authentication}/>
        <Route path="/acesso-negado" component={prazoExpiradoPage}/>
        <Route path="*" component={naoEncontradoPage}/>
      </Switch>
    </BrowserRouter>
  );
}

export default RoutesSettings;
