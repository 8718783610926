import React from 'react';


import useStyles from './footer.styles';



function Footer() {
  const styles = useStyles();
  return (
    <div className={styles.div__root}>
      <div className={styles.div__versao}>
        <span>
          Versão 1.0.0
        </span>
      </div>
      <div className={styles.div__techno}>
        <span>
          Powered by Techno Software Ltda
        </span>
        </div>
        </div>

  );
}

export default Footer;
