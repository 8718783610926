import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  topicTitle: {
    marginBottom: '30px',
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '16px',
    },
  },
});
