import {
  AUTHENTICATE_FAILED, SET_ATHENTICATE, SET_AUTH_VALUES, SET_LOADING,
} from './autenticacao.constants';
import { getAuthDataFromSession } from '../../utils/utils';

function buildInitialStates() {
  return {
    isAuthenticated: getAuthDataFromSession(),
    loading: false,
    errorMessage: '',
    isInfoNaoEncontrada: true,
    isPrazoExpirado: false,
    expiredIn: '',
  };
}

export const setLodingAction = value => ({
  type: SET_LOADING,
  value,
});

export const setAuthenticateAction = value => ({
  type: SET_ATHENTICATE,
  value,
});

export const setAuthenticationFailureAction = (value, isInfoNaoEncontrada) => ({
  type: AUTHENTICATE_FAILED,
  value,
  isInfoNaoEncontrada,
});

export const setAuthenticationValuesAction = (name, value) => ({
  type: SET_AUTH_VALUES,
  name,
  value,
});

function setAuthenticateHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    isAuthenticated: value,
  };
}

function setLoadingHandler(states, actions) {
  const { value } = actions;
  return {
    ...states,
    loading: value,
  };
}

function setAuthValuesHandler(states, actions) {
  const { name, value } = actions;
  return {
    ...states,
    [name]: value,
  };
}

function setAuthenticationFailureHandler(states, actions) {
  const { value, isInfoNaoEncontrada } = actions;
  return {
    ...states,
    errorMessage: value,
    isInfoNaoEncontrada,
  };
}

export default function (states = buildInitialStates(), actions) {
  switch (actions.type) {
    case SET_ATHENTICATE:
      return setAuthenticateHandler(states, actions);
    case SET_LOADING:
      return setLoadingHandler(states, actions);
    case AUTHENTICATE_FAILED:
      return setAuthenticationFailureHandler(states, actions);
    case SET_AUTH_VALUES:
      return setAuthValuesHandler(states, actions);
    default:
      return states;
  }
}
