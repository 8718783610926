import * as faq from './faq.constants';

function buildPerguntaResposta(pergunta, resposta, panelId) {
  return {
    pergunta,
    resposta,
    panelId,
  };
}

export const sobreAcordoSeguroFaq = () => ({
  topics: {
    name: 'Sobre o Acordo Seguro',
    perguntas: [
      buildPerguntaResposta(faq.OQUE_EH_PERGUNTA, faq.OQUE_EH_RESPOSTA, 'panel1'),
      buildPerguntaResposta(faq.CONFIAVEL_PAGAR_PERGUNTA, faq.CONFIAVEL_PAGAR_RESPOSTA, 'panel2'),
      buildPerguntaResposta(faq.NAO_ACESSAR_PERGUNTA, faq.NAO_ACESSAR_RESPOSTA, 'panel3'),
      buildPerguntaResposta(faq.COMO_FALAR_PERGUNTA, faq.COMO_FALAR_RESPOSTA, 'panel4'),
    ],
  },
});

export const sobreDividaFaq = () => ({
  topics: {
    name: 'Sobre a Dívida',
    perguntas: [
      buildPerguntaResposta(faq.ONDE_CONSULTAR_DETALHE_PERGUNTA, faq.ONDE_CONSULTAR_DETALHE_RESPOSTA, 'panel5'),
      buildPerguntaResposta(faq.CONTRA_PROPOSTA_PERGUNTA, faq.CONTRA_PROPOSTA_RESPOSTA, 'panel6'),
      buildPerguntaResposta(faq.NAO_RECONHECO_DIVIDA_PERGUNTA, faq.NAO_RECONHECO_DIVIDA_RESPOSTA, 'panel7'),
      buildPerguntaResposta(faq.POSSO_NEGOCIAR_QQR_MOMENTO_DIVIDA_PERGUNTA, faq.POSSO_NEGOCIAR_QQR_MOMENTO_DIVIDA_RESPOSTA, 'panel8'),
    ],
  },
});

export const sobrePagamentoFaq = () => ({
  topics: {
    name: 'Sobre o Pagamento',
    perguntas: [
      buildPerguntaResposta(faq.COMO_PAGAR_DIVIDA_PERGUNTA, faq.COMO_PAGAR_DIVIDA_RESPOSTA, 'panel8'),
      buildPerguntaResposta(faq.NAO_GERAR_BOLETO_PERGUNTA, faq.NAO_GERAR_BOLETO_RESPOSTA, 'panel9'),
      buildPerguntaResposta(faq.GEREI_BOLETO_NAO_PAGOU_PERGUNTA, faq.GEREI_BOLETO_NAO_PAGOU_RESPOSTA, 'panel10'),
      buildPerguntaResposta(faq.COMO_TIRAR_SEG_VIA_BOLETO_PERGUNTA, faq.COMO_TIRAR_SEG_VIA_BOLETO_RESPOSTA, 'panel11'),
    ],
  },
});

export const sobreAcordoFaq = () => ({
  topics: {
    name: 'Sobre o Acordo',
    perguntas: [
      buildPerguntaResposta(faq.COMO_SABER_ACORDO_APROVADO_PERGUNTA, faq.COMO_SABER_ACORDO_APROVADO_RESPOSTA, 'panel12'),
      buildPerguntaResposta(faq.POSSO_CANCELAR_ACORDO_PERGUNTA, faq.POSSO_CANCELAR_ACORDO_RESPOSTA, 'panel13'),
      buildPerguntaResposta(faq.DIVIDA_VISIVEL_PERGUNTA, faq.DIVIDA_VISIVEL_RESPOSTA, 'panel14'),
      buildPerguntaResposta(faq.NAO_CONSEGUIR_CUMPRIR_PERGUNTA, faq.NAO_CONSEGUIR_CUMPRIR_RESPOSTA, 'panel15'),
    ],
  },
});

