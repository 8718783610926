import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  subTitleContainer: {
    paddingBottom: '3.7%',
    '& p': {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'center',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
    },
  },
});
