import makeStyles from '@material-ui/core/styles/makeStyles';
import { BLACK_TWO, NAVY_BLUE, WHITE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  gridResumoDivida: {
    backgroundColor: NAVY_BLUE,
    color: 'white',
  },
  gridTotal: {
    marginTop: 'auto',
  },
  gridH2: {
    '& h2': {
      marginTop: 0,
      textAlign: 'center',
      '@media screen and (max-width: 600px)': {
        fontSize: '3.125rem',
      },
      '@media screen and (max-width: 1279px)': {
        fontSize: '2.5rem',
      },
    },
  },
  gridBoxShadow: {
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    backgroundColor: WHITE,
    padding: '10.1%',
  },
  gridResumo: {
    padding: '0rem 1.625rem 1.625rem 1.625rem',
    '& p': {
      fontWeight: 'bold',
      color: BLACK_TWO,
      fontSize: '1.25rem',
    },
  },
  lineTopBottom: {
    borderTop: '1px solid #a2a0a0',
    borderBottom: '1px solid #a2a0a0',
    width: '80%',
    textAlign: 'center',
    padding: '1rem 0px',
  },
  lineBottom: {
    borderBottom: '1px solid #a2a0a0',
    width: '80%',
    textAlign: 'center',
    padding: '16px 0px',
  },
  lineTop: {
    borderTop: '1px solid #a2a0a0',
    width: '80%',
    textAlign: 'center',
    padding: '16px 0px',
  },
  lineSpacing: {
    textAlign: 'center',
    padding: '16px 0px',
  },
  divButtonResumo: {
    textAlign: 'center',
    padding: '42px 0px 0px 0px',
  },
  divTitleFormaPagamento: {
    padding: '0rem 0rem 1.625rem 0rem',
    '& p': {
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  pixCardButton: {
    height: '100%',
    '@media screen and (max-height: 375px)': {
      height: '196px',
    },
  },
  pixGridAlign: {
    paddingBottom: '18px',
  },
  boletoCardButton: {
    height: '100%',
  },
});
