import React from 'react';
import RoutesSettings from './routes/RoutesSettings';

function App() {
  return (
    <RoutesSettings />
  );
}

export default App;
