import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import useStyles from './resumoDivida.styles';
import { formatMoney, saveObjectSessionStorage } from '../../../utils/utils';

import {
  BLANK,
  HONORARIOS_TITLE,
  IMPRIMIR_BOLETO_LABEL,
  VER_COMPROVANTE_LABEL,
  JUROS_E_DESPESAS_TITLE,
  NEGOCIAR_BUTTON_LABEL,
  OUTROS_SUB_TITLE,
  OUTROS_TITLE,
  RESUMO_BUTTON_LABEL,
  RESUMO_TITLE,
  TITLE_FORMA_PAGAMENTO,
  TOTAL_TITLE,
  VALOR_PRINCIPAL_TITLE,
  IMPRIMIR_QR_CODE_LABEL,
} from './resumoDivida.constants';
import FadeUI from '../../UI/Transitions/FadeUI';
import SubTitle from '../../UI/Titles/SubTitle';
import imgCartaoCredito from '../../../assets/Buttons/cartao-de-credito.png';
import imgCartaoCreditoDisabled from '../../../assets/Buttons/cartao-de-credito-disabled.png';
import imgCodigoBararras from '../../../assets/Buttons/codigo-de-barras.png';
import imgCodigoBararrasDisabled from '../../../assets/Buttons/codigo-de-barras-disabled.png';
import imgPix from '../../../assets/Buttons/pix.png';
import imgPixDisabled from '../../../assets/Buttons/pix-disabled.png';
import CardButton from '../../UI/Button/CardButtonUI';
import ImagemCardButton from '../../../models/ImagemCardButton';
import ButtonUI from '../../UI/Button/ButtonUI';
import { getBoletoAcordoAction, getDadosReciboAction, getPixAcordoAction } from '../../../store/home/home.saga';
import ComprovantePagamentoCartao from '../../UI/Recibo/ComprovantePagamentoCartao';
import PassoUI from '../../UI/PassoUI/PassoUI';

const CardButtonsModel = [
  new ImagemCardButton(imgPix, imgPixDisabled, 'PIX', 'Negociar com Pix', 'auto', 45),
  new ImagemCardButton(imgCodigoBararras, imgCodigoBararrasDisabled, 'BOLETO BANCÁRIO', 'Negociar com boleto bancário', 60, 40),
  new ImagemCardButton(imgCartaoCredito, imgCartaoCreditoDisabled, 'CARTÃO DE CRÉDITO', 'Negociar com cartão de crédito', 60, 40),
];

function ResumoDivida({ dividas, history }) {
  const dispatch = useDispatch();
  const styles = useStyles();

  const loadingHome = useSelector(states => states.homeStore.actionsPageView.loadingHome);
  const utilizarBoleto = useSelector(states => states.homeStore.actionsPageView.utilizarBoleto);
  const utilizarCartao = useSelector(states => states.homeStore.actionsPageView.utilizarCartao);
  const utilizarPix = useSelector(states => states.homeStore.actionsPageView.utilizarPix);
  const idProcesso = useSelector(store => store.homeStore.searchCriteria.idProcesso);
  const idRecebimento = useSelector(store => store.homeStore.searchCriteria.idRecebimento);
  const tipoAcordo = useSelector(store => store.homeStore.searchCriteria.tipoAcordoRealizado);
  const dadosRecibo = useSelector(states => states.homeStore.dadosRecibo);


  const [isNegociar, setIsNegociar] = useState(false);
  const [openComprovante, setOpenComprovante] = useState(false);

  // Grava o idProcesso no storage com o nome de idFotoUsuario afim de "camuflar".
  useEffect(() => {
    if (idProcesso) {
      saveObjectSessionStorage('idFotoUsuario', idProcesso);
    }
  }, [idProcesso]);

  const onCloseComprovanteHandler = useCallback(() => {
    setOpenComprovante(false);
  }, [setOpenComprovante]);

  const onSetIsNegociarHandler = useCallback(() => {
    setIsNegociar(!isNegociar);
  }, [isNegociar]);

  const onImprimirBoletoHandler = useCallback(() => {
    dispatch(getBoletoAcordoAction(idRecebimento));
  }, [idRecebimento, dispatch]);

  const onImprimirQRCodeHandler = useCallback(() => {
    dispatch(getPixAcordoAction(idRecebimento));
  }, [idRecebimento, dispatch]);

  const onVerComprovanteHandler = useCallback(() => {
    if (dadosRecibo.length === 0) {
      dispatch(getDadosReciboAction(idProcesso));
    }
    setOpenComprovante(true);
  }, [idProcesso, dispatch, setOpenComprovante, dadosRecibo]);

  // Grava a forma de pagamento no storage com o nome de imgQuality afim de "camuflar".
  const onNegociarComPixHandler = useCallback(() => {
    saveObjectSessionStorage('imgQuality', 1);
    history.push('/negociar');
  }, [history]);

  // Grava a forma de pagamento no storage com o nome de imgQuality afim de "camuflar".
  const onNegociarComBoletoHandler = useCallback(() => {
    saveObjectSessionStorage('imgQuality', 2);
    history.push('/negociar');
  }, [history]);

  // Grava a forma de pagamento no storage com o nome de imgQuality afim de "camuflar".
  const onNegociarComCartaoHandler = useCallback(() => {
    saveObjectSessionStorage('imgQuality', 3);
    history.push('/negociar');
  }, [history]);

  const formatValue = (value) => {
    const money = formatMoney(value);
    return `${BLANK}${money}`;
  };

  const getButtonAposAcessoComponent = () => {
    if (tipoAcordo === 'B') {
      return (
        <ButtonUI label={IMPRIMIR_BOLETO_LABEL} onClick={onImprimirBoletoHandler} variant="primary" />
      );
    }
    if (tipoAcordo === 'C') {
      return (
        <ButtonUI label={VER_COMPROVANTE_LABEL} onClick={onVerComprovanteHandler} variant="primary" />
      );
    }
    if (tipoAcordo === 'P') {
      return (
        <ButtonUI label={IMPRIMIR_QR_CODE_LABEL} onClick={onImprimirQRCodeHandler} variant="primary" />
      );
    }
    return (
      <>
        <PassoUI numero="1" />
        <ButtonUI label={NEGOCIAR_BUTTON_LABEL} onClick={onSetIsNegociarHandler} variant="primary" />
      </>
    );
  };

  const getResumoDividaComponent = () => (
    <>
      <Grid container item lg={12} alignItems="center" justify="center" className={styles.gridResumo}>
        <SubTitle title={RESUMO_TITLE} />
      </Grid>
      <Grid container item lg={12} alignItems="center" justify="center">
        <div className={styles.lineTopBottom}>
          <Typography component="p">
            {VALOR_PRINCIPAL_TITLE}
            {formatValue(dividas.valorPrincipal)}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="center" justify="center">
        <div className={styles.lineBottom}>
          <Typography component="p">
            {OUTROS_TITLE}
            {formatValue(dividas.valorTotalOutros)}
          </Typography>
          <Typography component="p">{OUTROS_SUB_TITLE}</Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="center" justify="center">
        <div className={styles.lineSpacing}>
          <Typography component="p">
            {JUROS_E_DESPESAS_TITLE}
            {formatValue(dividas.valorJuros)}
          </Typography>
        </div>
      </Grid>
      <Grid container item lg={12} alignItems="center" justify="center">
        <div className={styles.lineTop}>
          <Typography component="p">
            {HONORARIOS_TITLE}
            {formatValue(dividas.valorHonorarios)}
          </Typography>
        </div>
      </Grid>
      <Grid container item alignItems="center" justify="center" lg={12} className={styles.lineSpacing}>
        {getButtonAposAcessoComponent()}
      </Grid>
      <ComprovantePagamentoCartao dadosRecibo={dadosRecibo} open={openComprovante} onCloseHandler={onCloseComprovanteHandler} />
    </>
  );

  const getNegociarComponent = () => (
    <>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} className={styles.divTitleFormaPagamento}>
          <PassoUI numero="2" />
          <Typography component="p">
            {TITLE_FORMA_PAGAMENTO}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} sm={4} md={4} xs={4}>
            <CardButton
              onClick={onNegociarComPixHandler}
              disabled={!utilizarPix}
              imgCardButton={CardButtonsModel[0]}
              containerProps={{ className: styles.pixCardButton }}
              gridImgProps={{ className: styles.pixGridAlign }}
            />
          </Grid>
          <Grid item lg={4} sm={4} md={4} xs={4}>
            <CardButton
              onClick={onNegociarComBoletoHandler}
              disabled={!utilizarBoleto}
              imgCardButton={CardButtonsModel[1]}
            />
          </Grid>
          <Grid item lg={4} sm={4} md={4} xs={4}>
            <CardButton
              onClick={onNegociarComCartaoHandler}
              disabled={!utilizarCartao}
              imgCardButton={CardButtonsModel[2]}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" justify="center" lg={12} className={styles.divButtonResumo}>
          <ButtonUI label={RESUMO_BUTTON_LABEL} onClick={onSetIsNegociarHandler} variant="secondary" />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container item lg={12} alignItems="baseline" className={styles.gridResumoDivida}>
        <Grid container item lg={12} alignItems="center" justify="center" className={styles.gridTotal}>
          <h4>{TOTAL_TITLE}</h4>
        </Grid>
        <Grid container item lg={12} alignItems="center" justify="center" className={styles.gridH2}>
          <FadeUI loading={loadingHome} timeoutEffect={1000}>
            <h2>{formatValue(dividas.valorTotalProcesso)}</h2>
          </FadeUI>
        </Grid>
      </Grid>
      <Grid container item lg={12} md={12} sm={12} xl={12} className={styles.gridBoxShadow}>
        {isNegociar ? getNegociarComponent() : getResumoDividaComponent()}
      </Grid>
    </>
  );
}

export default withRouter(ResumoDivida);
