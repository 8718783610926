export default class ImagemTopicModel {
  constructor(id, scr, alt, imgMaxWidth, imgHeight, title, text, timeoutEffect) {
    this.id = id;
    this.scr = scr;
    this.alt = alt;
    this.imgMaxWidth = imgMaxWidth;
    this.imgHeight = imgHeight;
    this.title = title;
    this.text = text;
    this.timeoutEffect = timeoutEffect;
  }
}
