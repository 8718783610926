import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles({
  textPassos: {
    fontSize: '1.25rem !important',
    fontFamily: 'Roboto !important',
    fontWeight: 'bold !important',
    textTransform: 'upperCase !important',
    color: '#c77b00 !important',
    '@media screen and (max-width: 600px)': {
      fontSize: '1rem !important',
    },
  },
});
