import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE_SMOKE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  container: {
    backgroundColor: WHITE_SMOKE,
  },
  children: {
    maxWidth: '1280px',
    width: '100%',
    vw: '100%',
    margin: 'auto',
  },
});
