import React from 'react';
import logo from '../../assets/Imagens/logo.svg';
import capaPrincipal from '../../assets/Imagens/capaPrincipal.svg';
import iconSeta from '../../assets/Imagens/iconeSeta.svg';
import checkDouble from '../../assets/Imagens/check-double.svg';
import handPoint from '../../assets/Imagens/hand-point-up.svg';
import creditCard from '../../assets/Imagens/credit-card.svg';
import handShake from '../../assets/Imagens/handshake.svg';
import checkIcon from '../../assets/Imagens/check.svg';
import capaSecundaria from '../../assets/Imagens/capaSecundaria.svg';
import iconePix from '../../assets/Imagens/iconePix.svg';
import iconeBoleto from '../../assets/Imagens/iconeBoleto.svg';
import iconeCartao from '../../assets/Imagens/iconeCartao.svg';
import capaTerciaria from '../../assets/Imagens/capaTerciaria.svg';
import logotipoRodape from '../../assets/Imagens/logotipoRodape.svg';
import iconeInstagram from '../../assets/Imagens/iconeInstagram.svg';
import iconeFacebook from '../../assets/Imagens/iconeFacebook.svg';
import './landPage.styles.css';
import {generateUniqId} from '../../utils/utils';
import AcordionUI from '../UI/AcordionUI/AcordionUI';
import {useHistory, withRouter} from 'react-router';


const cleanHyperLink = {textDecoration: 'none', color: 'inherit'};

function LandPage() {
  const history = useHistory();

  return (
    <>
      <header className="cabecalho">
        <img src={logo} alt="Logo do Acordo Seguro"/>
        <nav className="cabecalho-menu">
          <a className="menu-item" href="#home">HOME</a>
          <a className="menu-item" href="#funciona">COMO FUNCIONA</a>
          <a className="menu-item" href="#pagamento">PAGAMENTO</a>
          <a className="menu-item" href="#duvidas">DÚVIDAS FREQUENTES</a>
        </nav>
      </header>

      <main className="conteudo">
        <a name="home"/>
        <section className="capa-home">
          <div className="capa-frase">
            <h1 className="capa-titulo">
              Negocie seus
              <br/>
              {' '}
              débitos de forma
            </h1>
            <h2 className="capa-subtitulo">
              <strong>
                rápida, eficaz e
                <br/>
                {' '}
                segura.
              </strong>
            </h2>
            <button type="button" className="botao" onClick={() => history.replace('/autenticar')}>
              <a
                target="_blank"
                style={cleanHyperLink}
              >
                <strong>NEGOCIAR AGORA</strong>
              </a>
            </button>
          </div>
          <img className="capa-imagem" src={capaPrincipal} alt="Capa Principal"/>
        </section>


        <section className="sessao1">
          <div className="sessao1-faixa">
            <img className="icone-seta" src={iconSeta} alt="Ícone seta"/>
            <h4 className="frase-faixa">Veja como funciona</h4>
            <img className="icone-seta" src={iconSeta} alt="Ícone seta"/>
          </div>
        </section>


        <section className="sessao2">
          <a name="funciona"/>
          <h3 className="sessao2-frase">
            Sem burocracia, você efetua seus
            <br/>
            pagamentos de
            {' '}
            <strong>
              forma prática e
              rápida.
            </strong>
          </h3>
          <div className="sessao2-cards">
            <div className="card">
              <img className="icone-card" src={checkDouble} alt="Ícone Check"/>
              <h4 className="frase-card">
                1º Clique em
                {' '}
                <strong>“Negociar”</strong>
              </h4>
            </div>

            <div className="card">
              <img className="icone-card" src={handPoint} alt="Ícone Indicador"/>
              <h4 className="frase-card">
                2º Selecione a melhor
                {' '}
                <strong>oferta para o seu bolso</strong>
              </h4>
            </div>

            <div className="card">
              <img className="icone-card" src={creditCard} alt="Ícone Pagamento"/>
              <h4 className="frase-card">
                3º Escolha uma forma
                {' '}
                <strong>de pagamento</strong>
              </h4>
            </div>

            <div className="card">
              <img className="icone-card" src={handShake} alt="Ícone Acordo"/>
              <h4 className="frase-card">
                4º Pague seu
                {' '}
                <strong>acordo e pronto!</strong>
              </h4>
            </div>
          </div>
        </section>


        <section className="sessao3">
          <div className="sessao3-texto">
            <h1 className="sessao3-titulo">
              A segurança de
              <br/>
              seus dados é
            </h1>
            <h2 className="sessao3-subtitulo">prioridade.</h2>
            <p className="sessao3-itens">
              <img src={checkIcon} alt=""/>
              Validação
            </p>
            <p className="sessao3-itens">
              <img src={checkIcon} alt=""/>
              Criptografia
            </p>
            <p className="sessao3-itens">
              <img src={checkIcon} alt=""/>
              Sigilo Absoluto
            </p>
            <p className="sessao3-itens">
              <img src={checkIcon} alt=""/>
              Ambiente Seguro
            </p>
            <button className="botao" onClick={() => history.replace('/autenticar')}>
              <a
                target="_blank"
                style={cleanHyperLink}
              >
                <strong>NEGOCIAR AGORA</strong>
              </a>
            </button>
          </div>
          <img className="sessao3-imagem" src={capaSecundaria} alt="Mulher sentada no sofá com o computador"/>
        </section>

        <section className="sessao4">
          <a name="pagamento"/>
          <h1 className="sessao4-titulo"><strong>Aceitamos os principais Meios de Pagamento</strong></h1>
          <div className="sessao4-circulos">
            <div className="circulo">
              <img className="icone-pix" src={iconePix} alt="Ícone PIX"/>
            </div>
            <div className="circulo">
              <img className="icone-boleto" src={iconeBoleto} alt="Ícone Boleto"/>
            </div>

            <div className="circulo">
              <img className="icone-cartao" src={iconeCartao} alt="Ícone Cartão"/>
            </div>
          </div>
          <div className="sessao4-subtitulo">
            <p className="titulo1-circulo"><strong>PIX</strong></p>
            <p className="titulo2-circulo"><strong>BOLETO</strong></p>
            <p className="titulo3-circulo"><strong>CARTÃO</strong></p>
          </div>
        </section>

        <section className="sessao5">
          <div className="sessao5-texto">
            <h3 className="sessao5-titulo">Vantagens e benefícios em negociar com a gente</h3>

            <h2 className="sessao5-subtitulo">
              Facilidade de
              {' '}
              <strong>Negociação</strong>
            </h2>
            <p className="sessao5-paragrafo">Negocie sem sair de casa com total conforto para você.</p>

            <h2 className="sessao5-subtitulo">
              Descontos e Ofertas
              {' '}
              <strong>Exclusivas</strong>
            </h2>
            <p className="sessao5-paragrafo">
              Garanta os melhores descontos com parcelas e condições especiais de acordo com as
              regras de cada parceiro.
            </p>

            <h2 className="sessao5-subtitulo">
              Confiável e
              {' '}
              <strong>Seguro</strong>
            </h2>
            <p className="sessao5-paragrafo">
              Pague seus débitos em poucos minutos com a segurança que só o Acordo Seguro pode
              oferecer.
            </p>
          </div>
          <img className="sessao5-imagem" src={capaTerciaria} alt="Capa Homem feliz no computador"/>
        </section>


        <section className="sessao6">
          <a name="duvidas"/>
          <h3 className="sessao6-titulo"><strong>Dúvidas Frequentes - Sobre o Acordo Seguro</strong></h3>
          <div style={{display: 'flex', width: '983px'}}>
            <AcordionUI
              key={generateUniqId()}
              panelId={0}
              acordionTitle="O que é o Acordo Seguro?"
              acordionText="O Acordo Seguro é um serviço 100% digital oferecido em parceria com alguns de nossos clientes para negociação de
              dívidas online."
            />
          </div>

          <div style={{display: 'flex', width: '983px'}}>
            <AcordionUI
              key={generateUniqId()}
              panelId={1}
              acordionTitle="É confiável pagar pelo Acordo Seguro?"
              acordionText="Sim. Como o próprio nome já diz, pagar a sua dívida através do site Acordo Seguro é muito seguro e confiável.
              Cada usuário recebe um acesso exclusivo com um prazo de expiração definido. O acesso é realizado através de um
              link que utiliza um rigoroso critério de segurança (https) e oferece uma conexão totalmente segura, onde suas
              informações (por exemplo, senhas ou número de cartão de crédito) permanecem privadas quando são enviadas para o
              site."
            />
          </div>


          <div style={{display: 'flex', width: '983px'}}>
            <AcordionUI
              key={generateUniqId()}
              panelId={2}
              acordionTitle="Por que não consigo acessar o site Acordo Seguro?"
              acordionText="Para acessar o site é necessário estar conectado à internet do seu smartphone ou computador. Se estiver tudo
              certo com a sua internet, verifique se o link que você está tentando acessar é o mesmo recebido por e-mail ou
              mensagem de texto em seu celular. Se ainda assim não conseguir acessar as informações do site, é provável que o
              seu link de acesso tenha expirado."
            />
          </div>

          <div style={{display: 'flex', width: '983px'}}>
            <AcordionUI
              key={generateUniqId()}
              panelId={3}
              acordionTitle="Como falar com o Acordo Seguro?"
              acordionText="Ficou com alguma dúvida e não encontrou o que precisa? Sem problemas! Fale com a gente pelo e-mail
              atendimento@acordoseguro.com.br ou então ligue +55 (11) 3156-4800."
            />
          </div>
        </section>

      </main>


      <footer className="rodape">
        <img className="rodape-logotipo" src={logotipoRodape} alt="Logotipo Acordo Seguro"/>
        <div className="rodape-texto">
          <h5 className="rodape-titulo">Fale conosco</h5>
          <p className="rodape-telefone">(11) 3156-4800</p>
          <p className="rodape-email">atendimento@acordoseguro.com.br</p>
        </div>

        <div className="rodape-menu">
          <div className="rodape-menu-ordenacao">
            <a className="rodape-menu-item" style={cleanHyperLink} href="#home">HOME</a>
            <a className="rodape-menu-item" style={cleanHyperLink} href="#funciona">COMO FUNCIONA</a>
          </div>
          <div className="rodape-menu-ordenacao">
            <a className="rodape-menu-item" style={cleanHyperLink} href="#pagamento">PAGAMENTO</a>
            <a className="rodape-menu-item" style={cleanHyperLink} href="#duvidas">DÚVIDAS FREQUENTES</a>
          </div>
        </div>

        <div className="rodape-redes-sociais">
          <a className="instagram" href="https://www.instagram.com/acordo_seguro/" target="_blank">
            <img
              src={iconeInstagram}
              alt="Link Instagram"
            />
          </a>
          <a className="facebook" href="https://www.facebook.com/oAcordoSeguro/" target="_blank">
            <img
              src={iconeFacebook}
              alt="Link Facebook"
            />
          </a>
        </div>
      </footer>
    </>
  );
}

export default withRouter(LandPage);
