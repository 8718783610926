import { makeStyles } from '@material-ui/core/styles';
import { BLUE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  formRoot: props => ({
    border: '0',
    margin: '0',
    display: 'inline-flex',
    padding: '0',
    position: 'relative',
    minWidth: '0',
    flexDirection: 'column',
    verticalAlign: 'top',
    '& label': {
      fontSize: '0.75rem',
      color: '#707070',
      marginBottom: '4px',
    },
    '& textarea': {
      resize: 'vertical',
      width: '100%',
      backgroundColor: '#48484820',
      border: 'none',
      padding: '3px 6px 3px',
      fontSize: '1rem',
      fontFamily: 'Roboto, Arial',
      borderRadius: '2px',
      color: props.disabled ? '#777470' : '#1e1e1e',
      borderBottom: '1px solid #878787',
      lineHeight: '1.2rem',
      overflow: 'hidden',
      outline: 'none',
    },
    '& textarea:focus': {
      borderBottom: `2px solid ${BLUE}`,
    },
    '&:focus-within': {
      '& label': {
        color: BLUE,
      },
    },
  }),
  fullWidth: {
    width: '100%',
  },
  disabled: {
    borderBottom: '1px dotted #878787',
  },
  counter: {
    display: 'block',
    position: 'relative',
    '& span': {
      float: 'right',
      fontSize: '0.75rem',
      color: '#707070',
    },
  },
  error: {
    '& label': {
      color: '#e91b1b',
    },
    '& textarea': {
      borderBottom: '2px solid #e91b1b !important',
    },
  },
});
