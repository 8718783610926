import React, { memo } from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import useStyles from './tableUI.styles';
import { formatDate, formatMoney } from '../../../utils/utils';
import FadeUI from '../Transitions/FadeUI';

function TableUI({ columns, rows }) {
  const styles = useStyles();

  const loading = useSelector(states => states.homeStore.actionsPageView.loading);

  function formatValue(value, column) {
    if (column.type === 'money') {
      return formatMoney(value);
    }
    if (column.type === 'date') {
      return formatDate(value, false);
    }

    return value;
  }

  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      <Table stickyHeader className={styles.tableColumnHeader}>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <Typography component="p">
                  {column.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow
              className={styles.tableCell}
              hover
              tabIndex={-1}
              key={row.numero}
            >
              {columns.map(column => (
                <FadeUI loading={loading} timeoutEffect={800}>
                  <TableCell key={column.id} align={column.align} variant="body">
                    <Typography component="p">
                      {formatValue(row[column.id], column)}
                    </Typography>
                  </TableCell>
                </FadeUI>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default memo(TableUI);
