import * as yup from 'yup';
import { formatMoney } from '../../../utils/utils';

export function getTopicName(idx) {
  switch (idx) {
    case 0: return 'Transação:';
    case 1: return 'Data:';
    case 2: return 'Forma de Pagamento:';
    case 3: return 'Pagamento:';
    case 4: return 'Total Pago:';
    default: return '';
  }
}

export function buildDadosReciboObject(dadosRecibo) {
  const qtdParcelaFormatada = `${dadosRecibo.qtdeParcelas}x`;
  return {
    transacao: dadosRecibo.transacao,
    dataTransacao: dadosRecibo.dataTransacao,
    formaPagamento: dadosRecibo.formaPagamento,
    pagamento: qtdParcelaFormatada,
    total: formatMoney(dadosRecibo.valorTotal),
  };
}

export const errosFormInitialState = {
  ddd: {
    error: false,
    message: '',
  },
  telefone: {
    error: false,
    message: '',
  },
  emailDestinatarioDevedor: {
    error: false,
    message: '',
  },
};

export function buildSchemaValidate(dadosEnviarCompravante) {
  let schemaToValidate;
  if (dadosEnviarCompravante.ddd === '' && dadosEnviarCompravante.telefone === '') {
    schemaToValidate = {
      emailDestinatarioDevedor: dadosEnviarCompravante.emailDestinatarioDevedor,
    };
  } else {
    schemaToValidate = {
      emailDestinatarioDevedor: dadosEnviarCompravante.emailDestinatarioDevedor,
      ddd: dadosEnviarCompravante.ddd,
      telefone: dadosEnviarCompravante.telefone,
    };
  }
  return schemaToValidate;
}

export function getErrorCelular(dddParam, telefoneMessageParam) {
  return dddParam || telefoneMessageParam;
}

export function atribuirErrosForm(e, errosForm) {
  e.inner.forEach((err) => {
    errosForm[err.path].error = true;
    errosForm[err.path].message = err.message;
  });
}

export const formComprovanteSchema = yup.object().shape({
  ddd: yup.string().nullable().min(2, 'Número inválido'),
  telefone: yup.string().nullable().min(9, 'Número inválido'),
  emailDestinatarioDevedor: yup.string().matches(/^[_A-Za-z0-9-\\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/, 'E-mail inválido')
    .required('Informe um e-mail'),
});
