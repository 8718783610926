import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SubTitle from '../../UI/Titles/SubTitle';
import PaperUI from '../../UI/Paper/PaperUI';
import CollapsibleTableUI from '../../UI/Tabela/CollapsibleTableUI';
import TableHeaderModel from '../../../models/TableHeaderModel';
import checkIcon from '../../../assets/Icons/Icon awesome-check-circle.png';
import useStyles from './dividas.styles';
import ZoomUI from '../../UI/Transitions/ZoomUI';

const columns = [
  new TableHeaderModel('processo', 'PROCESSO', 0, 'left'),
  new TableHeaderModel('cliente', 'CLIENTE', 0, 'center'),
  new TableHeaderModel('', '', 0, 'center'),
];

function Dividas({ outrasDividas = [] }) {
  const loading = useSelector(states => states.homeStore.actionsPageView.loading);
  const styles = useStyles();

  return ((outrasDividas.length > 0 || loading)
    ? (
      <PaperUI>
        <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12} xs={12}>
          <SubTitle title="CONSULTE SUAS DÍVIDAS" />
        </Grid>
        <Grid container item justify="center" alignItems="center">
          <CollapsibleTableUI columns={columns} rows={outrasDividas} />
        </Grid>
      </PaperUI>
    ) : (
      <PaperUI>
        <ZoomUI loading={loading}>
          <Grid container spacing={1}>
            <Grid container item justify="center" alignItems="center" lg={12} md={12} sm={12} xs={12}>
              <img
                className={styles.imgContainer}
                src={checkIcon}
                alt="Tudo certo"
              />
            </Grid>
            <Grid container item justify="center" className={styles.titleContainer}>
              <Typography component="h3">
                Tudo certo!
              </Typography>
            </Grid>
            <Grid container item justify="center" className={styles.subTitleContainer}>
              <Typography component="h4">
                Não encontramos outras dívidas em seu nome
              </Typography>
            </Grid>
          </Grid>
        </ZoomUI>
      </PaperUI>
    )
  );
}

export default memo(Dividas);
