import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PaperUI from '../../UI/Paper/PaperUI';
import AcordionUI from '../../UI/AcordionUI/AcordionUI';
import SubTitle from '../../UI/Titles/SubTitle';
import { TITLE_FAQ } from './faq.constants';
import useStyles from './faq.styles';
import {
  sobreAcordoFaq, sobreAcordoSeguroFaq, sobreDividaFaq, sobrePagamentoFaq,
} from './faq.utils';
import { generateUniqId } from '../../../utils/utils';

function Faq() {
  const styles = useStyles();

  const topicOne = sobreAcordoSeguroFaq();
  const topicTwo = sobreDividaFaq();
  const topicThree = sobrePagamentoFaq();
  const topicFour = sobreAcordoFaq();

  return (
    <PaperUI>
      <SubTitle title={TITLE_FAQ} upperCase />
      <Grid
        container
        item
        justify="flex-start"
        alignItems="flex-start"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.topicTitle}
      >
        <Typography component="h3">{topicOne.topics.name}</Typography>
        {topicOne.topics.perguntas.map(faq => (
          <AcordionUI
            key={generateUniqId()}
            panelId={faq.panelId}
            acordionTitle={faq.pergunta}
            acordionText={faq.resposta}
          />
        ))}
      </Grid>
      <Grid
        container
        item
        justify="flex-start"
        alignItems="flex-start"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.topicTitle}
      >
        <Typography component="h3">{topicTwo.topics.name}</Typography>
        {topicTwo.topics.perguntas.map(faq => (
          <AcordionUI
            key={generateUniqId()}
            panelId={faq.panelId}
            acordionTitle={faq.pergunta}
            acordionText={faq.resposta}
          />
        ))}
      </Grid>
      <Grid
        container
        item
        justify="flex-start"
        alignItems="flex-start"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.topicTitle}
      >
        <Typography component="h3">{topicThree.topics.name}</Typography>
        {topicThree.topics.perguntas.map(faq => (
          <AcordionUI
            key={generateUniqId()}
            panelId={faq.panelId}
            acordionTitle={faq.pergunta}
            acordionText={faq.resposta}
          />
        ))}
      </Grid>
      <Grid
        container
        item
        justify="flex-start"
        alignItems="flex-start"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={styles.topicTitle}
      >
        <Typography component="h3">{topicFour.topics.name}</Typography>
        {topicFour.topics.perguntas.map(faq => (
          <AcordionUI
            key={generateUniqId()}
            panelId={faq.panelId}
            acordionTitle={faq.pergunta}
            acordionText={faq.resposta}
          />
        ))}
      </Grid>
    </PaperUI>
  );
}

export default Faq;
