import React, { useCallback } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import useStyles from './tabCardUI.styles';

function TabCardUI({
  children,
  tabItens,
  parentStates,
}) {
  const [value, setValue] = parentStates;
  const styles = useStyles();

  const tabChangeHandler = useCallback((event, newValue) => {
    setValue(newValue);
  }, [setValue]);

  return (
    <div className={styles.appBar}>
      <Tabs
        value={value}
        onChange={tabChangeHandler}
        variant="scrollable"
        classes={{ indicator: styles.tabSelectColor }}
        scrollButtons="auto"
      >
        {tabItens.map(
          item => (
            <Tab
              disabled={item.disabled}
              key={item.id}
              id={item.id}
              label={item.label}
              aria-controls={item.ariaControl}
            />
          ),
        )}
      </Tabs>
      <div>{children}</div>
    </div>
  );
}

export default TabCardUI;
