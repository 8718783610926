import makeStyles from '@material-ui/core/styles/makeStyles';
import { NAVY_BLUE } from '../../../utils/colors/colors.contants';

export default makeStyles({
  imgContainer: {
    height: 'auto',
    width: 'auto',
    maxwidth: '70px',
    maxHeight: '70px',
    '@media screen and (max-width: 380px)': {
      maxwidth: '45px',
      maxHeight: '45px',
    },
  },
  titleContainer: {
    '& h3': {
      color: NAVY_BLUE,
      fontSize: '1.560rem',
      '@media screen and (max-width: 600px)': {
        fontSize: '1.125rem',
      },
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  subTitleContainer: {
    '& h4': {
      color: NAVY_BLUE,
      fontSize: '1.250rem',
      '@media screen and (max-width: 600px)': {
        fontSize: '1rem',
      },
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
});
